import React from 'react'
import { FormGrid } from './style'
import CardInput from './CardInput'
import { CardInput as CardInputProps } from './CardInput'

type CardFormProps = {
  types: CardInputProps[]
  handleClick: (values: string) => void
  skipCard?: SkipCard
}

type SkipCard = {
  logo: string
  label: string
  description?: string
}

const CardForm = ({ types, handleClick }: CardFormProps): JSX.Element => {
  const handleTypeClick = ({ disabled, value }: CardInputProps) => {
    if (!disabled) {
      handleClick(value)
    }
  }
  return (
    <>
      <FormGrid>
        {types.map((type, index) => {
          return (
            <span key={index} onClick={() => handleTypeClick(type)}>
              <CardInput {...type} />
            </span>
          )
        })}
      </FormGrid>
      <>
        {/* <FormFlex>
            {
              types.map((type, index) => {
                return (
                  <span key={index} onClick={() => handleClick(type.value)}>
                    <CardInput value={type.value} logo={type.logo} label={type.label} description={type.description} />
                  </span>
                )
              })
            }
            {( skipCard ) && (
              <span>
                <CardInput value={types[1].value} logo={types[1].logo} label={types[1].label} />
              </span>
            )}
        </FormFlex> */}
      </>
    </>
  )
}

export default CardForm
