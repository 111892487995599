import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CardWithTitle = styled.a`
  ${tw`
    flex
    flex-col
    items-center
    w-36
  `}
  .label {
    ${tw`
      mt-8
      font-medium
      text-lg
      text-indigo-900
    `}
  }
  .description {
    ${tw`
      mt-0
      font-medium
      text-sm
      text-gray-400
    `}
  }
`
