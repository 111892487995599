import { gql } from '@apollo/client/core'

export const GET_SUBMISSION_STEP = gql`
  query get_submission_step($id: uuid!) {
    submissions_by_pk(id: $id) {
      id
      contact_billing_id
      first_application_id
      first_api_id
      funnel_step
      funnel_substep
      processing_state
      tenant_id
    }
  }
`
