import React from 'react'
import euIconSvg from '../../images/eu-icon.svg'
import usIconSvg from '../../images/us-icon.svg'
import Headline from '../Headline'
import SubStepNavigation from '../SubStepsNavigation'
import CardForm from '../CardForm'
import { CardInput } from '../CardForm/CardInput'
import { useMutation } from '@apollo/client'
import { UPDATE_TENANT_REGION } from '../../graphql/mutations/tenant_region'
import Spinner from '../elements/Spinner'
import ErrorPage from '../ErrorPage'
import { GET_SUBMISSION_STEP } from '../../graphql/queries/submission'
import { useNextStepSubStep } from '../../utils/step'

type TenantRegionFormProps = {
  id: string
  step: number
  subStep: number
  tenantId: string | null
}

interface tenantRegionProps extends CardInput {
  id: string
}

const TENANT_REGION_TYPES: tenantRegionProps[] = [
  {
    id: 'eu',
    value: 'eu',
    logo: euIconSvg,
    label: 'EU Zone',
    description: 'Frankfurt',
  },
  {
    id: 'us',
    value: 'us',
    logo: usIconSvg,
    label: 'USA Zone',
    description: 'Oregon',
    disabled: true,
    tooltip_text: 'Soon',
  },
]

const TenantRegionForm = (props: TenantRegionFormProps): JSX.Element => {
  const { id, tenantId, step, subStep } = props
  const [nextStep, nextStepSubStep] = useNextStepSubStep(step, subStep)
  const [updateTenantRegion, { loading, error }] = useMutation(UPDATE_TENANT_REGION, {
    refetchQueries: [
      {
        query: GET_SUBMISSION_STEP,
        variables: {
          id: id,
        },
      },
    ],
  })

  if (loading) return <Spinner />
  if (error) return <ErrorPage error={error} description="Can’t submit tenant region" />

  const handleClick = (region: string) => {
    const tenantRegionParams = {
      id: id,
      tenantId: tenantId,
      region: region,
      funnel_step: nextStep,
      funnel_substep: nextStepSubStep,
    }
    updateTenantRegion({
      variables: tenantRegionParams,
    })
  }

  return (
    <>
      <Headline title="The region where your app runs" />
      <CardForm handleClick={handleClick} types={TENANT_REGION_TYPES} />
      <SubStepNavigation />
    </>
  )
}

export default TenantRegionForm
