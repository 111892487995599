import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CryptrClientBlockStyle = styled.div`
  ${tw`
    flex
    flex-col
  `}
  h4 {
    ${tw`
      mt-4
      mb-6
      font-bold
      uppercase
      text-indigo-500
    `}
  }
  p {
    ${tw`
      my-6
      text-gray-300
      font-medium
    `}
  }
`

export const LogoWrapper = styled.div`
  ${tw`
    rounded-full
    h-16
    w-16
    flex
    justify-center
    items-center
    bg-white
  `}
  svg {
    ${tw`
      h-10
      w-10
    `}
  }
`
