import styled from '@emotion/styled'
import tw from 'twin.macro'

export const SmallCard = styled.div`
  ${tw`
    bg-white
    h-36
    w-36
    flex
    justify-center
    items-center
    shadow-lg
    rounded-lg
    cursor-pointer
    hover:shadow-xl
    transition
    duration-500
    ease-in-out
    transform
    hover:-translate-y-2
  `}
`
export const DisabledSmallCard = styled.div`
  ${tw`
    bg-gray-200
    h-36
    w-36
    flex
    justify-center
    items-center
    border
    disabled:opacity-50
    shadow-lg
    rounded-lg
    cursor-not-allowed
  `}
  svg {
    ${tw`
      disabled:opacity-50`}
  }
`

export const IconCard = styled.div`
  ${tw`
    h-20
    w-20
  `}
  svg {
    ${tw`
      h-20
      w-20
    `}
  }
`
