import ProgressArc from 'progress-arc-component'
import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CongratulationFx = styled.div`
  ${tw`
    absolute
    z-0
  `}
`
export const ActionButton = styled.button`
  ${tw`
    py-2
    px-4
    rounded
    inline-flex
    items-center
    text-gray-500
    hover:text-gray-400
  `}
  &:focus {
    outline: none;
  }
  svg {
    ${tw`
      h-6
      w-6
      text-gray-500
    `}
  }
`

export const DoneMessagesStyle = styled.div`
  ${tw`
    pt-4
  `}
  ul {
    ${tw`
      px-20
      flex-auto
      overflow-y-auto
    `}
  }
`

export const LogoWrapper = styled.div`
  ${tw`
    flex
    justify-center
    mb-12
  `}
`

export const ProcessingCard = styled.li`
  ${tw`
    rounded-md
    bg-white
    py-3
    px-4
    mb-6
  `}
  box-shadow: 0px 7.31355px 21.9406px rgba(0, 0, 0, 0.07);
  div {
    ${tw`
      flex
      items-center
    `}
  }
`
export const WaitingLoaderWrapper = styled.div`
  ${tw`
    flex
    items-center
    justify-center
  `}
`

export const ProcessingCardIcon = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    rounded-full
    h-8
    w-8
  `}
  &.current {
    ${tw`
      border-gray-300
    `}
  }
  &.done {
    ${tw`
      border-green-300
    `}
  }
  border-width: 3px;
  svg {
    ${tw`
      h-4
      w-4
    `}
    color: #54D2C4;
  }
  span {
    ${tw`
      text-xs
    `}
  }
`

export const ProcessingCardText = styled.div`
  ${tw`
    ml-4
    flex
    flex-col
  `}
  h4 {
    ${tw`
      text-sm
      font-medium
      text-indigo-900
    `}
    &.done {
      ${tw`
        line-through
        text-gray-500
      `}
    }
  }
  div {
    ${tw`
      mt-1
      w-full
      text-gray-300
    `}
    font-size: 9px;
  }
`

export const ProcessingStateHeading = styled.h3`
  ${tw`
    text-xs
  `}
  &.in-progress {
    ${tw`
      text-indigo-400
    `}
  }
  &.success {
    ${tw`
      text-green-500
    `}
  }
`

export const Redirection = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    mb-4
    h-6
  `}
  h2,
  p {
    ${tw`
      text-lg
      text-indigo-900
      font-medium
    `}
  }
`

export const StyledProcessingPage = styled.div`
  ${tw`
    w-full
    h-full
    md:px-36
    lg:px-48
    py-16
    flex
    justify-center
    text-center
  `}
  background: linear-gradient(198.74deg, #FEFEFF 0%, #F3F3FB 100%);
`

export const DonePageText = styled.div`
  ${tw`
    w-1/2
    pr-10
    pl-6
    z-10
  `}
  p {
    ${tw`
      text-indigo-900
      text-base
      mb-8
    `}
  }
  svg {
    ${tw`
      mb-4
    `}
  }
`

export const DonePageHero = styled.div`
  ${tw`
    flex
    flex-row
    w-full
  `}
`

export const DonePageImage = styled.div`
  ${tw`
    w-1/2
    rounded-t-lg
    overflow-hidden
    mt-10
    mr-6
  `}
`

export const ProgressBar = styled(ProgressArc)`
  height: 12em;
  width: 12em;
  border-radius: 0.5em;
  padding: 1em;
`

export const StyledDonePage = styled.div`
  ${tw`
    w-full
    h-full
    relative
    px-48
    py-28
  `}
  background: linear-gradient(198.74deg, #FEFEFF 0%, #F3F3FB 100%);
`

export const StarterAppCard = styled.div`
  ${tw`
    rounded-2xl
    -mt-24
    absolute
    p-6
  `}
  width: calc(100% - 384px);
  background: linear-gradient(197.55deg, #373f69 33.52%, #1d2039 100%);
`

export const AppAndApiRow = styled.div`
  ${tw`
    flex
    flex-row
  `}
`

export const StarterAppTitle = styled.div`
  ${tw`
    text-white
    text-2xl
    mb-10
  `}
`

export const ButtonsGroup = styled.div`
  ${tw`
    flex
    flex-row
  `}
`
