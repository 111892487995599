import React from 'react'
import { Application } from '../../../utils/interfaces'
import { APP_TYPES } from '../../ApplicationForm'
import SVG from 'react-inlinesvg'
import { CopyBlock, nord } from 'react-code-blocks'
import LgButton from '../../elements/LgButton'
import { ButtonCol } from '../../style'
import { CRYPTR_DOCS_BASE_URL } from '../../../constants'
import { CryptrClientBlockStyle, LogoWrapper } from './style'
import TargetBlankIcon from '../../../images/target-blank-icon.svg'

const CryptrClientBlock = ({
  application,
  clientId,
}: {
  application: Application
  clientId: string
}): JSX.Element => {
  const { application_type } = application
  const appType = APP_TYPES.find((type: { value: string }) => type.value === application_type)
  const isReact = application_type === 'react'
  const docUrl = `${CRYPTR_DOCS_BASE_URL}/getting-started/single-page-applications/${
    application.application_type
  }/${isReact ? '' : 'authentication'}`
  const goToGettingStarted = () => {
    window.open(docUrl, '_blank')
  }
  return (
    <CryptrClientBlockStyle>
      <LogoWrapper>{appType && <SVG src={appType?.logo} />}</LogoWrapper>
      <h4>SINGLE PAGE APP</h4>
      <CopyBlock showLineNumbers={false} text={clientId} theme={nord} codeBlock />
      <p>Start by integrating Cryptr with in your Angular Application</p>
      <ButtonCol>
        <LgButton style={{ width: 'auto' }} onClick={() => goToGettingStarted()}>
          Get your app started with our doc
          <SVG src={TargetBlankIcon} />
        </LgButton>
      </ButtonCol>
    </CryptrClientBlockStyle>
  )
}

export default CryptrClientBlock
