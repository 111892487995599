import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledMain = styled.div`
  ${tw`
    w-2/3
    h-screen
    px-48
    py-28
    flex
    flex-col
    justify-between
  `}
  background: linear-gradient(198.74deg, #FCFAFF 0%, #F3F3FB 100%);
`
