import { gql } from '@apollo/client/core'

export const GET_TENANT_APPLICATION_AND_API = gql`
  query getTenantApplicationAndApi(
    $tenant_id: uuid!,
    $first_api_id: uuid!,
    $first_application_id: uuid!) {
    first_applications_by_pk(id: $first_application_id) {
      id
      application_type
      appAudience: audience
      redirect_uri
      locale
      locale_enum {
        value
        key
      }
      created_at
      updated_at
    }
    first_apis_by_pk(id: $first_api_id) {
      id
      api_type
      apiAudience: audience
      created_at
      updated_at
    }
    tenants_by_pk(id: $tenant_id) {
      id
      name
      domain
      region
      region_enum {
        key
        value
      }
    }
  }
`

export const GET_TENANT_APPLICATION = gql`
  query getTenantApplicationAndApi(
    $tenant_id: uuid!,
    $first_application_id: uuid!) {
    first_applications_by_pk(id: $first_application_id) {
      id
      application_type
      appAudience: audience
      redirect_uri
      locale
      locale_enum {
        value
        key
      }
      created_at
      updated_at
    }
    tenants_by_pk(id: $tenant_id) {
      id
      name
      domain
      region
      region_enum {
        key
        value
      }
    }
  }
`

export const GET_TENANT_REGION = gql`
  query getTenantRegion($tenant_id: uuid!) {
    tenants_by_pk(id: $tenant_id) {
      id
      region
      region_enum {
        key
        value
      }
    }
  }
`
