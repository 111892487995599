import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledSummary = styled.div`
  ${tw`
    w-1/3
    h-full
    h-screen
    px-16
    py-6
  `}
  background: linear-gradient(198.74deg, #1E214A 0%, #161B5B 33.23%, #3F2096 100%);
`
