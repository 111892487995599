import React, { useEffect, useState } from 'react'
import {
  AppAndApiRow,
  ButtonsGroup,
  CongratulationFx,
  DonePageHero,
  DonePageImage,
  DonePageText,
  StarterAppTitle,
  StyledDonePage,
} from './style'
import cryptrLogoSvg from '../../images/cryptr-logo-vb.svg'
import congratulationFxSvg from '../../images/congratulation-fx.svg'
import SVG from 'react-inlinesvg'
import { useParams } from 'react-router'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { GET_SUBMISSION_STEP } from '../../graphql/queries/submission'
import {
  GET_TENANT_APPLICATION,
  GET_TENANT_APPLICATION_AND_API,
} from '../../graphql/queries/tenant_appli_and_api'
import CryptrConfigBlock from './CryptrConfigBlock'
import axios from 'axios'
import { adminToolUrl, fetchClientIdUrl } from '../../utils/cryptr'
import { ButtonCol } from '../style'
// import LgButton from '../elements/LgButton'
import LgButtonWhite from '../elements/LgButtonWhite'
import CryptrClientBlock from './CryptrClientBlock'
import { StarterAppCard } from './style'
import Headline from '../Headline'
import CryptrAppImg from '../../images/cryptr-app.jpg'

type ResultProps = {
  clientId?: string
}
const Result = ({ clientId }: ResultProps): JSX.Element => {
  const [currentClientId, setCurrentClientId] = useState(clientId || '')
  const client = useApolloClient()
  const { id }: { id: string } = useParams()

  useEffect(() => {
    if (currentClientId === '') {
      axios
        .get(fetchClientIdUrl(id))
        .then(({ data }: { data: string }) => {
          setCurrentClientId(data)
        })
        .catch((error: unknown) => {
          console.error(error)
        })
    }
  }, [id, currentClientId])

  const cachedSubmission = client.readQuery({
    query: GET_SUBMISSION_STEP,
    variables: {
      id: id,
    },
  })
  const {
    submissions_by_pk: { tenant_id, first_api_id, first_application_id },
  } = cachedSubmission
  const resourcesVariables = {
    tenant_id: tenant_id,
    first_api_id: first_api_id,
    first_application_id: first_application_id,
  }
  const resourceQuery = first_api_id ? GET_TENANT_APPLICATION_AND_API : GET_TENANT_APPLICATION
  const [
    loadResources,
    { data: resourcesData, loading: loadingResources, called: calledResources },
  ] = useLazyQuery(resourceQuery, {
    variables: resourcesVariables,
  })

  const goToAdminTool = () => {
    window.open(adminToolUrl, '_blank')
  }

  const sampleRender = (
    <StyledDonePage>
      <DonePageHero>
        <DonePageText>
          <SVG src={cryptrLogoSvg} />
          <h1>We&rsquo;ll contact you soon</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus massa nibh, lacinia
            vitae felis eu, molestie semper ex.{' '}
          </p>
        </DonePageText>
        <CongratulationFx>
          <SVG src={congratulationFxSvg} />
        </CongratulationFx>
      </DonePageHero>
    </StyledDonePage>
  )

  if (!calledResources) {
    loadResources()
    return sampleRender
  } else if (!loadingResources) {
    const {
      first_applications_by_pk: appli,
      first_apis_by_pk: api,
      tenants_by_pk: tenant,
    } = resourcesData
    return (
      <StyledDonePage>
        <CongratulationFx>
          <SVG src={congratulationFxSvg} />
        </CongratulationFx>
        <DonePageHero>
          <DonePageText>
            <SVG src={cryptrLogoSvg} />
            <Headline title="Your account is ready" />
            <p>
              Using our Cryptr backoffice, within the next 30 minutes, you will make authentication
              easier for your users.
            </p>
            <ButtonsGroup>
              {/* <ButtonCol style={{ marginRight: '1.5rem' }}>
                <LgButton>Download on Mac</LgButton>
              </ButtonCol> */}
              <ButtonCol>
                <LgButtonWhite onClick={() => goToAdminTool()}>
                  {/* <LgButtonWhite
                  title={'Will be available soon'}
                  disabled={true}
                  onClick={() => goToAdminTool()}
                > */}
                  Open your admin tool
                </LgButtonWhite>
              </ButtonCol>
            </ButtonsGroup>
          </DonePageText>
          <DonePageImage>
            <img src={CryptrAppImg} />
          </DonePageImage>
        </DonePageHero>
        <StarterAppCard>
          <StarterAppTitle>
            <h2>We will cover how to get started your app 😉</h2>
          </StarterAppTitle>
          <AppAndApiRow>
            <CryptrClientBlock application={appli} clientId={currentClientId} />
            <CryptrConfigBlock
              clientId={currentClientId}
              application={appli}
              api={api}
              tenant={tenant}
            />
          </AppAndApiRow>
        </StarterAppCard>
      </StyledDonePage>
    )
  } else {
    return sampleRender
  }
}

export default Result
