import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

const GRAPHQL_DOMAIN =
  process.env.REACT_APP_API_GRAPHQL_DOMAIN || `cryptr-developer-onboarding-hasura.onrender.com`

const HEADERS = {
  headers: {
    'x-hasura-admin-secret': process.env.REACT_APP_HASURA_API_KEY || `6c7b550bf961b494050755e63a313aee`,
  },
}

const HTTPLink = new HttpLink({
  ...HEADERS,
  uri:
    process.env.NODE_ENV === 'development'
      ? `http://localhost:8080/v1/graphql`
      : `https://${GRAPHQL_DOMAIN}/v1/graphql`,
})

const WSLink = new WebSocketLink({
  options: {
    connectionParams: HEADERS,
    lazy: true,
    reconnect: true,
  },
  uri:
    process.env.NODE_ENV === 'development'
      ? `ws://localhost:8080/v1/graphql`
      : (`wss://${GRAPHQL_DOMAIN}/v1/graphql` as string),
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  WSLink,
  HTTPLink,
)

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: splitLink,
})

export default apolloClient
