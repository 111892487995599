import { gql } from '@apollo/client/core'

export const CREATE_SUBMISSION_ID = gql`
  mutation CreateSubmisionMutation($email: String!) {
    insert_submissions_one(
      object: { contact_billing: { data: { email: $email } }, first_application: { data: {} } }
    ) {
      __typename
      id
      funnel_step
      funnel_substep
      contact_billing_id
      first_application_id
      processing_state
    }
  }
`

export const UPDATE_FUNNEL_STEP = gql`
  mutation UPDATE_STEP($id: uuid!, $funnel_step: Int!, $funnel_substep: Int!) {
    update_submissions_by_pk(
      pk_columns: { id: $id }
      _set: { funnel_step: $funnel_step, funnel_substep: $funnel_substep }
    ) {
      __typename
      id
      contact_billing_id
      funnel_step
      funnel_substep
      processing_state
    }
  }
`

export const UPDATE_FUNNEL_SUBSTEP = gql`
  mutation UpdateSubstep($id: uuid!, $funnel_substep: Int!) {
    update_submissions_by_pk(pk_columns: { id: $id }, _set: { funnel_substep: $funnel_substep }) {
      __typename
      id
      contact_billing_id
      funnel_step
      funnel_substep
      processing_state
    }
  }
`
