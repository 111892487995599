import { useApolloClient, useMutation } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { UPDATE_FUNNEL_STEP } from '../../../graphql/mutations/submission'
import { GET_SUBMISSION_STEP } from '../../../graphql/queries/submission'
import {
  ProgressCheckCircle,
  ProgressCheckText,
  ProgressCompleteLine,
  ProgressCurrentCircle,
  ProgressCurrentText,
  ProgressItem,
  ProgressLine,
  ProgressLink,
  ProgressNextCircle,
  ProgressNextText,
  StyledProgress,
} from './style'

interface Step {
  title: string
  subtitle: string
  subSteps: Array<Step>
}

type StepsProps = {
  steps: Array<Step>
  currentStepIndex: number
}

const CompletedStep = ({ currentStepIndex, step }: { step: Step; currentStepIndex: number }) => {
  const { id }: { id: string } = useParams()
  const [updateStep, { loading, error }] = useMutation(UPDATE_FUNNEL_STEP)

  const client = useApolloClient()
  const { submissions_by_pk } = client.readQuery({
    query: GET_SUBMISSION_STEP,
    variables: {
      id: id,
    },
  })

  const handleClick = (submissionId: string, requestedIndex: number) => {
    if (submissions_by_pk.funnel_step <= requestedIndex) return

    updateStep({ variables: { id: submissionId, funnel_step: requestedIndex, funnel_substep: 0 } })
  }

  if (loading) return <></>
  if (error) return <></>

  return (
    <div onClick={() => handleClick(id, currentStepIndex)}>
      <ProgressItem>
        <ProgressLink href="#">
          <ProgressCompleteLine />
          <ProgressCheckCircle>
            <span>
              <span className="complete-number">{currentStepIndex + 1}</span>
            </span>
          </ProgressCheckCircle>
          <ProgressCheckText>
            <span className="title">{step.title}</span>
            <span className="subtitle">{step.subtitle}</span>
          </ProgressCheckText>
        </ProgressLink>
      </ProgressItem>
    </div>
  )
}

const CurrentStep = ({ step, last }: { step: Step; last: boolean }) => (
  <ProgressItem>
    <ProgressLink href="#">
      {last ? '' : <ProgressLine />}
      <ProgressCurrentCircle>
        <span className="round">
          <span className="round-check"></span>
        </span>
      </ProgressCurrentCircle>
      <ProgressCurrentText>
        <span className="title">{step.title}</span>
        <span className="subtitle">{step.subtitle}</span>
      </ProgressCurrentText>
    </ProgressLink>
  </ProgressItem>
)

const NextStep = ({ step, last }: { last: boolean; step: Step }) => (
  <ProgressItem>
    <ProgressLink href="#">
      {last ? '' : <ProgressLine />}
      <ProgressNextCircle>
        <span className="round">
          <span className="round-check"></span>
        </span>
      </ProgressNextCircle>
      <ProgressNextText>
        <span className="title">{step.title}</span>
        <span className="subtitle">{step.subtitle}</span>
      </ProgressNextText>
    </ProgressLink>
  </ProgressItem>
)

const Steps = ({ currentStepIndex, steps }: StepsProps): JSX.Element => {
  return (
    <nav aria-label="Progress">
      <StyledProgress>
        {steps.map((step, index) => {
          if (currentStepIndex === index) {
            return (
              <CurrentStep
                key={`summary-step-${index}`}
                step={step}
                last={index === steps.length - 1}
              />
            )
          } else if (currentStepIndex < index) {
            return (
              <NextStep
                key={`summary-step-${index}`}
                last={index === steps.length - 1}
                step={step}
              />
            )
          } else {
            return (
              <CompletedStep key={`summary-step-${index}`} step={step} currentStepIndex={index} />
            )
          }
        })}
      </StyledProgress>
    </nav>
  )
}

export default Steps
