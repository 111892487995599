import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FormGrid = styled.div`
  ${tw`
    grid
    md:grid-cols-2
    lg:grid-cols-3
    w-full
    my-16
    flex-auto
    min-h-0
    overflow-y-auto
  `}
`
