import React, { useEffect, useState } from 'react'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { useParams } from 'react-router'
import { SUBSCRIBE_SUBMISSION_STEP } from '../../graphql/subscriptions/submission'
import { EN } from '../../locales/en'
import Spinner from '../elements/Spinner'
import Result from './Result'
import {
  ActionButton,
  DoneMessagesStyle,
  LogoWrapper,
  ProcessingCard,
  ProcessingCardIcon,
  ProcessingCardText,
  ProcessingStateHeading,
  ProgressBar,
  Redirection,
  StyledProcessingPage,
  WaitingLoaderWrapper,
} from './style'
import axios from 'axios'
import { fetchClientIdUrl } from '../../utils/cryptr'
import cryptrLogoGraySvg from '../../images/cryptr-logo-gray.svg'
import cryptrLogoColorSvg from '../../images/cryptr-logo-color.svg'
import infoIcon from '../../images/info-icon.svg'
import checkIcon from '../../images/thin-border-check-icon.svg'
import SVG from 'react-inlinesvg'
import Loader from 'react-loader-spinner'
import { GET_CONTACT_BILLING } from '../../graphql/queries/contact_billing'

const SECONDS_BEFORE_RESULT = 10

const MAX_STEPS_SHOW = 3

const PROCESSING_STATE_ENUM: string[] = [
  // 1. Introduction
  'not_started',
  'request_processing',

  // 2. Progression
  'tenant_created',
  'first_application_created',
  'first_api_created',
  'public_jwk_created',
  'public_jwks_created',

  // 3. Done
  'account_ready',
]

const isProgressionDone = (currentState: string, progressionState: string): boolean =>
  PROCESSING_STATE_ENUM.indexOf(progressionState) < PROCESSING_STATE_ENUM.indexOf(currentState)

const DONE_MESSAGES: Record<string, Record<string, string>> = {
  tenant_created: {
    title: 'Your tenancy is created',
    subtitle: 'Your domain is registered',
  },
  first_application_created: {
    title: 'Your first application declaration is created',
    subtitle: 'A client app has been registered with provided settings',
  },
  first_api_created: {
    title: 'Your first API declaration is declared',
    subtitle: 'A back-end app has been registered with provided settings',
  },
  private_jwk_created: {
    title: 'Your private Json Web Key is created',
    subtitle: 'Your private JWK has been created',
  },
  public_jwks_created: {
    title: 'Your public Json Web Key is created',
    subtitle: 'Your public JWK has been created',
  },
  account_ready: {
    title: 'Your account is ready',
    subtitle: 'Your account reached final step',
  },
}

const isValidState = (state: string) => PROCESSING_STATE_ENUM.includes(state)

const positionProcessing = (state: string): number => PROCESSING_STATE_ENUM.indexOf(state)

const percentageProcessing = (currentState: string): number =>
  Math.round((positionProcessing(currentState) / (PROCESSING_STATE_ENUM.length - 1)) * 100)

const progressionSteps = (currentState: string) => {
  return Object.keys(DONE_MESSAGES)
    .filter((doneMessageKeyState: string) => {
      return isProgressionDone(currentState, doneMessageKeyState)
    })
    .reverse()
}

const CurrentMessage = ({
  currentMessageKeyState,
  currentStep,
}: {
  currentMessageKeyState: string
  currentStep: number
}) => {
  return (
    <ProcessingCard>
      {DONE_MESSAGES.hasOwnProperty(currentMessageKeyState) && (
        <div>
          <ProcessingCardIcon className="current">
            <span>{currentStep}</span>
          </ProcessingCardIcon>
          <ProcessingCardText>
            <h4 className="current">{DONE_MESSAGES[currentMessageKeyState].title}</h4>
            <div>
              <p>{DONE_MESSAGES[currentMessageKeyState].subtitle}</p>
            </div>
          </ProcessingCardText>
        </div>
      )}
    </ProcessingCard>
  )
}

const DoneMessage = ({ doneMessageKeyState }: { doneMessageKeyState: string }) => {
  return (
    <ProcessingCard key={doneMessageKeyState}>
      {DONE_MESSAGES.hasOwnProperty(doneMessageKeyState) && (
        <div>
          <ProcessingCardIcon className="done">
            <SVG src={checkIcon} />
          </ProcessingCardIcon>
          <ProcessingCardText>
            <h4 className="done">{DONE_MESSAGES[doneMessageKeyState].title}</h4>
            <div>
              <p>{DONE_MESSAGES[doneMessageKeyState].subtitle}</p>
            </div>
          </ProcessingCardText>
        </div>
      )}
    </ProcessingCard>
  )
}
const DoneMessages = ({ currentState }: { currentState: string }) => {
  const [showMore, setShowMore] = useState(false)
  const doneSteps = progressionSteps(currentState)

  const canShowMore = doneSteps.length > MAX_STEPS_SHOW

  return (
    <DoneMessagesStyle>
      <ul>
        {currentState !== 'request_processing' && (
          <CurrentMessage
            currentMessageKeyState={currentState}
            currentStep={doneSteps.length + 1}
          />
        )}
        {doneSteps
          .slice(0, MAX_STEPS_SHOW)
          .reverse()
          .map((doneMessageKeyState: string) => {
            return (
              <DoneMessage key={doneMessageKeyState} doneMessageKeyState={doneMessageKeyState} />
            )
          })}
        {canShowMore &&
          showMore &&
          doneSteps
            .slice(MAX_STEPS_SHOW, doneSteps.length)
            .reverse()
            .map((doneMessageKeyState: string) => {
              return (
                <DoneMessage key={doneMessageKeyState} doneMessageKeyState={doneMessageKeyState} />
              )
            })}
        {canShowMore && (
          <li>
            <ActionButton onClick={() => setShowMore(!showMore)}>
              <SVG src={infoIcon} />
              <span>
                {showMore
                  ? 'Show less actions'
                  : `Other actions done (${doneSteps.length - MAX_STEPS_SHOW})`}
              </span>
            </ActionButton>
          </li>
        )}
      </ul>
    </DoneMessagesStyle>
  )
}

const Timer = ({
  currentState,
  renderResult,
  contactBillingId,
}: {
  currentState: string
  renderResult: (clientId: string, loginUrl: string) => boolean
  contactBillingId: string
}): JSX.Element => {
  const { id }: { id: string } = useParams()
  const [clientId, setClientId] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const [timerBeforeResult, setTimerBeforeResult] = useState(SECONDS_BEFORE_RESULT)
  const [
    loadContact,
    { data: contactData, loading: loadingContact, called: calledContact },
  ] = useLazyQuery(GET_CONTACT_BILLING, {
    variables: { id: contactBillingId },
  })

  const timerInProgress = (): void => {
    if (timerBeforeResult < 1) return

    setTimeout(() => {
      //your code to be executed after 1 second
      if (timerBeforeResult < 1) return
      setTimerBeforeResult(timerBeforeResult - 1)
    }, 1000)
  }
  useEffect(() => {
    if (currentState === 'account_ready' && loginUrl === '') {
      if (!calledContact) {
        loadContact()
      } else if (!loadingContact) {
        if (contactData && contactData.contact_billings_by_pk.login_url !== null) {
          const {
            contact_billings_by_pk: { login_url },
          } = contactData
          setLoginUrl(login_url)
        } else if (clientId == '') {
          axios
            .get(fetchClientIdUrl(id))
            .then(({ data }: { data: string }) => {
              setClientId(data)
            })
            .catch((error: unknown) => {
              console.error(error)
            })
        }
      }
    }
  }, [
    id,
    currentState,
    contactData,
    clientId,
    loginUrl,
    calledContact,
    loadingContact,
    loadContact,
  ])

  useEffect(() => {
    if (currentState !== 'account_ready') return
    if (clientId === '' && loginUrl === '') return
    timerInProgress()
  })

  if (currentState !== 'account_ready') return <></>

  if (timerBeforeResult < 1) {
    renderResult(clientId, loginUrl)
  }

  if (timerBeforeResult < 1) return <Loader type="ThreeDots" color="#27114C" height={24} />

  return <h2>You’ll be redirected in {timerBeforeResult} seconds</h2>
}

const Processing = (): JSX.Element => {
  const { id }: { id: string } = useParams()
  const [clientId, setClientId] = useState('')
  const [loginUrl, setLoginUrl] = useState('')
  const [showResult, setShowResult] = useState(false)

  const renderResult = (clientId: string, loginUrl: string): boolean => {
    setClientId(clientId)
    setLoginUrl(loginUrl)
    setShowResult(true)
    return showResult
  }

  const { data, loading } = useSubscription(SUBSCRIBE_SUBMISSION_STEP, { variables: { id } })

  if (loading) return <Spinner />

  const { processing_state } = data.submissions_by_pk
  if (!isValidState(processing_state)) <h1>Error</h1>

  if (showResult) {
    if (loginUrl !== '') {
      window.location.href = loginUrl
      return <></>
    } else {
      return <Result clientId={clientId} />
    }
  }

  const {
    submissions_by_pk: { contact_billing_id },
  } = data
  return (
    <StyledProcessingPage>
      <div>
        <LogoWrapper>
          {processing_state === 'account_ready' ? (
            <SVG src={cryptrLogoGraySvg} />
          ) : (
            <SVG src={cryptrLogoColorSvg} />
          )}
        </LogoWrapper>
        <ProcessingStateHeading
          className={processing_state === 'account_ready' ? 'success' : 'in-progress'}
        >
          {EN.processing[processing_state]}
        </ProcessingStateHeading>
        <ProgressBar
          value={percentageProcessing(processing_state)}
          arcColor="#5367FF"
          textColor="#32376F"
          rounded={true}
        />
        {processing_state != 'account_ready' && (
          <WaitingLoaderWrapper>
            <Loader type="ThreeDots" color="#27114C" height={24} />
          </WaitingLoaderWrapper>
        )}
        <Redirection>
          <Timer
            contactBillingId={contact_billing_id}
            currentState={processing_state}
            renderResult={renderResult}
          />
        </Redirection>
        <DoneMessages currentState={processing_state} />
      </div>
    </StyledProcessingPage>
  )
}

export default Processing
