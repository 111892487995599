import { gql } from '@apollo/client/core'

export const GET_APPLICATION = gql`
  query getApplication($first_application_id: uuid!) {
    first_applications_by_pk(id: $first_application_id) {
      id
      application_type
      appAudience: audience
      redirect_uri
      locale
      created_at
      updated_at
    }
  }
`
