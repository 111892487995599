import { gql } from '@apollo/client/core'

export const UPDATE_APPLICATION_AND_API_SETTINGS = gql`
  mutation updateApplicationAndApiSettings($id: uuid!, $funnel_step: Int!, $funnel_substep: Int!, $first_application_id: uuid!, $appAudience: String, $apiAudience: String, $first_api_id: uuid!, $redirect_uri: String, $locale: String) {
    update_first_applications_by_pk(pk_columns: {id: $first_application_id}, _set: {audience: $appAudience, redirect_uri: $redirect_uri, locale: $locale}) {
      appAudience: audience
      redirect_uri
      locale
    }
    update_first_apis_by_pk(pk_columns: {id: $first_api_id}, _set: {audience: $apiAudience}) {
      apiAudience: audience
    }
    update_submissions_by_pk(pk_columns: {id: $id}, _set: {funnel_step: $funnel_step, funnel_substep: $funnel_substep}) {
      id
      first_application_id
      funnel_step
      funnel_substep
    }
  }
`

export const UPDATE_APPLICATION_SETTINGS = gql`
  mutation updateApplication($id: uuid!, $funnel_step: Int!, $funnel_substep: Int!, $first_application_id: uuid!, $appAudience: String, $redirect_uri: String, $locale: String) {
    update_first_applications_by_pk(pk_columns: {id: $first_application_id}, _set: {audience: $appAudience, redirect_uri: $redirect_uri, locale: $locale}) {
      appAudience: audience
      redirect_uri
      locale
    }
    update_submissions_by_pk(pk_columns: {id: $id}, _set: {funnel_step: $funnel_step, funnel_substep: $funnel_substep}) {
      id
      first_application_id
      funnel_step
      funnel_substep
    }
  }
`
