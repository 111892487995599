import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledHeadline = styled.div`
  ${tw`
    mb-10
  `}
`

export const HeadlineText = styled.h2`
  ${tw`
    font-medium
    text-indigo-900
    text-4xl
  `}
`
