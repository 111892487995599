import { gql } from '@apollo/client/core'

export const UPDATE_TENANT_NAME_DOMAIN = gql`
  mutation updateTenantDomainAndRegion(
    $id: uuid!
    $tenantId: uuid!
    $name: String!
    $domain: String!
    $requested_processing_at: timestamptz!
  ) {
    update_tenants_by_pk(
      pk_columns: {id: $tenantId},
      _set: {
        name: $name,
        domain: $domain
      }
    ) {
      id
      domain
      name
    }

    update_submissions_by_pk(
      pk_columns: { id: $id }
      _set: {
        requested_processing_at: $requested_processing_at
        processing_state: "request_processing"
      }
    ) {
      id
      contact_billing_id
      funnel_step
      funnel_substep
    }
  }
`

export const EU_UPDATE_TENANT_NAME_DOMAIN = gql`
  mutation updateTenantDomainAndEuRegion(
    $id: uuid!
    $tenantId: uuid!
    $name: String!
    $domain: String!
    $requested_processing_at: timestamptz!
  ) {
    update_tenants_by_pk(
      pk_columns: {id: $tenantId},
      _set: {
        name: $name,
        domain: $domain
      }
    ) {
      id
      domain
      name
    }

    update_submissions_by_pk(
      pk_columns: { id: $id }
      _set: {
        eu_requested_processing_at: $requested_processing_at
        processing_state: "request_processing"
      }
    ) {
      id
      contact_billing_id
      funnel_step
      funnel_substep
    }
  }
`

export const US_UPDATE_TENANT_NAME_DOMAIN = gql`
  mutation updateTenantDomainAndUsRegion(
    $id: uuid!
    $tenantId: uuid!
    $name: String!
    $domain: String!
    $requested_processing_at: timestamptz!
  ) {
    update_tenants_by_pk(
      pk_columns: {id: $tenantId},
      _set: {
        name: $name,
        domain: $domain
      }
    ) {
      id
      domain
      name
    }

    update_submissions_by_pk(
      pk_columns: { id: $id }
      _set: {
        us_requested_processing_at: $requested_processing_at
        processing_state: "request_processing"
      }
    ) {
      id
      contact_billing_id
      funnel_step
      funnel_substep
    }
  }
`
