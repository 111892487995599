import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledHeader = styled.div`
  ${tw`
    mt-12
    mx-auto
    max-w-7xl
  `}
  svg {
    ${tw`
      w-32
    `}
  }
`

export const HeaderText = styled.div`
  ${tw`
    text-left
    text-white
  `}
  h2 {
    ${tw`
      mt-24
      text-2xl
      tracking-tight
      font-medium
    `}
  }
  p {
    ${tw`
      mt-3
      text-base
      text-gray-300
    `}
  }
`
