import React from 'react'
import './App.css'
import Layout from './components/Layout'
import { ApolloProvider, useApolloClient, useQuery } from '@apollo/client'
import apolloClient from './graphql/apolloClient'
import { BrowserRouter as Router, Switch, Route, useParams, Redirect } from 'react-router-dom'
// import { useNextStepSubStep } from './utils/step'
// import { UPDATE_FUNNEL_STEP } from './graphql/mutations/submission'
import { GET_SUBMISSION_STEP } from './graphql/queries/submission'
import Spinner from './components/elements/Spinner'
import ErrorPage from './components/ErrorPage'
// import LgButton from './components/elements/LgButton'
import ApiForm from './components/ApiForm'
import ApplicationForm from './components/ApplicationForm'
import TenantRegionForm from './components/TenantRegionForm'
import SettingsForm from './components/SettingsForm'
import DonePage from './components/DonePage'
import { EN } from './locales/en'
import TenantDomainForm from './components/TenantDomainForm'

const INVALID_UUID = 'invalid input syntax for type uuid: '

// TODO: @melanie FunnelProps should be merged with subByPK, with renamings
type FunnelProps = {
  id: string
  step: number
  subStep: number
  contactBillingId: string
  firstApplicationId: string
  firstApiId: string | null
  tenantId: string | null
}

type subByPK = {
  funnel_step: number
  funnel_substep: number
  contact_billing_id: string
  processing_state: string | null
  first_application_id: string
  first_api_id: string | null
  tenant_id: string | null
}

const isFunnelInProgress = (processing_state: string | null): boolean =>
  processing_state === null || processing_state === 'not_started'

const Funnel = (props: FunnelProps) => {
  const { step, subStep } = props
  const currentSubStep = EN.steps[step].subSteps[subStep]
  if (currentSubStep === undefined) {
    throw `[ERROR] current substep not available for ${step}/${subStep}`
  }
  const compName = currentSubStep.componentName
  if (compName === undefined) {
    throw `[ERROR] Please provide a valid componentName for currentSubStep & currentStep position`
  }
  switch (compName) {
    case 'ApplicationForm':
      return <ApplicationForm {...props} />
    case 'ApiForm':
      return <ApiForm {...props} />
    case 'SettingsForm':
      return <SettingsForm {...props} />
    case 'TenantDomainForm':
      return <TenantDomainForm {...props} />
    case 'TenantRegionForm':
      return <TenantRegionForm {...props} />
    default:
      console.error(`Unknown ${compName} component`)
      throw `[ERROR] Please provide a valid componentName for currentSubStep & currentStep position`
      break
  }
}

// const TestTransition = ({
//   id,
//   currentStep,
//   currentSubStep,
// }: {
//   id: string
//   currentStep: number
//   currentSubStep: number
// }) => {
//   const [updateStep, { loading, error }] = useMutation(UPDATE_FUNNEL_STEP)
//   const [nextStep, nextSubStep] = useNextStepSubStep(currentStep, currentSubStep)

//   if (loading) return <Spinner />

//   if (error) {
//     return <ErrorPage error={error} description="Can’t go to the next step" />
//   }

//   const handleSubmitNext = (id: string, nextStep: number, nextSubStep: number) => {
//     return () =>
//       updateStep({ variables: { id: id, funnel_step: nextStep, funnel_substep: nextSubStep } })
//   }

//   return (
//     <>
//       <h1>
//         Current step {currentStep}.{currentSubStep}
//       </h1>
//       <hr />
//       <LgButton onClick={handleSubmitNext(id, nextStep, nextSubStep)}>
//         Go to the next step : {nextStep}.{nextSubStep}
//       </LgButton>
//       <hr />
//       <hr />
//       <br />
//     </>
//   )
// }

const RenderApp = () => {
  const client = useApolloClient()
  const { id }: { id: string } = useParams()
  const { loading, error, data } = useQuery(GET_SUBMISSION_STEP, {
    variables: { id: id },
  })
  if (loading) return <Spinner />

  if (!data || !data.submissions_by_pk) {
    return <Redirect to="/error/404" />
  }

  if (error) {
    if (error.message.includes(INVALID_UUID)) return <Redirect to="/error/404" />

    return <ErrorPage error={error} description="Undefined error" />
  }

  const {
    funnel_step,
    funnel_substep,
    contact_billing_id,
    processing_state,
    first_application_id,
    first_api_id,
    tenant_id,
  }: subByPK = data.submissions_by_pk

  const cachedSubmission = client.readQuery({
    query: GET_SUBMISSION_STEP,
    variables: {
      id: id,
    },
  })
  return (
    <div style={{ height: '100%' }}>
      {isFunnelInProgress(processing_state) ? (
        <Layout
          currentStepIndex={cachedSubmission.submissions_by_pk.funnel_step}
          currentSubStepIndex={cachedSubmission.submissions_by_pk.funnel_substep}
        >
          <>
            {/* {process.env.NODE_ENV === 'development' && (
              <>
                <TestTransition id={id} currentStep={funnel_step} currentSubStep={funnel_substep} />
                <hr />
              </>
            )} */}
            <Funnel
              id={id}
              step={funnel_step}
              subStep={funnel_substep}
              contactBillingId={contact_billing_id}
              firstApplicationId={first_application_id}
              firstApiId={first_api_id}
              tenantId={tenant_id}
            />
          </>
        </Layout>
      ) : (
        <DonePage />
      )}
    </div>
  )
}

const Render404 = () => {
  return <h1>404</h1>
}

const App = (): JSX.Element => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Switch>
          <Route path="/:id" component={RenderApp} />
          <Route path="*" component={Render404} />
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

export default App
