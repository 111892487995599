import React from 'react'
import golangLogoSvg from '../../images/go-lang-logo.svg'
import noBackEndLogoSvg from '../../images/no-back-end-logo.svg'
import nodeExpressPassportjsLogoSvg from '../../images/node-express-passportjs-logo.svg'
import phpLaravelLogoSvg from '../../images/php-laravel-logo.svg'
import phpSymfonyLogoSvg from '../../images/php-symfony-logo.svg'
// import pythonFlaskLogoSvg from '../../images/python-flask-logo.svg'
import Headline from '../Headline'
import SubStepNavigation from '../SubStepsNavigation'
import CardForm from '../CardForm'
import { CardInput } from '../CardForm/CardInput'
import { useMutation } from '@apollo/client'
import {
  CREATE_FIRST_API,
  REMOVE_FIRST_API,
  UPDATE_SUBMISSION_API,
} from '../../graphql/mutations/first_api'
import Spinner from '../elements/Spinner'
import ErrorPage from '../ErrorPage'
import { useNextStepSubStep } from '../../utils/step'
import { UPDATE_FUNNEL_STEP } from '../../graphql/mutations/submission'

interface apiTypesProps extends CardInput {
  id: string
  params: ParamsProps
}

type ParamsProps = {
  audience: string | null
}

const API_TYPES: apiTypesProps[] = [
  {
    id: 'golang',
    value: 'go',
    logo: golangLogoSvg,
    label: 'Go',
    params: {
      audience: 'http://localhost:8000',
    },
  },
  {
    id: 'node_express_passportjs',
    value: 'node_express',
    logo: nodeExpressPassportjsLogoSvg,
    label: 'Node (Express) + PassportJS',
    params: {
      audience: 'http://localhost:3000',
    },
  },
  {
    id: 'php_laravel',
    value: 'php_laravel',
    logo: phpLaravelLogoSvg,
    label: 'PHP Laravel',
    params: {
      audience: 'http://localhost:4000',
    },
  },
  {
    id: 'php_symfony',
    value: 'php_symfony',
    logo: phpSymfonyLogoSvg,
    label: 'PHP Symfony',
    params: {
      audience: 'http://localhost:8000',
    },
  },
  // {
  //   id: 'python_flask',
  //   value: 'python_flask',
  //   logo: pythonFlaskLogoSvg,
  //   label: 'Python Flask',
  //   params: {
  //     audience: 'http://localhost:8000',
  //   },
  // },
  {
    id: 'no_back_end',
    value: 'no_back_end',
    logo: noBackEndLogoSvg,
    label: 'No back-end',
    params: {
      audience: null,
    },
  },
]

type ApiFormProps = {
  firstApiId: string | null
  id: string
  step: number
  subStep: number
}

const ApiForm = ({ firstApiId, id, step, subStep }: ApiFormProps): JSX.Element => {
  const [
    removeFirstApi,
    { loading: loadingRemoveFirstApi, error: errorRemoveFirstApi },
  ] = useMutation(REMOVE_FIRST_API)

  const [
    updateSubmissionFirstApiId,
    { loading: loadingUpdateMutation, error: errorUpdateMutation },
  ] = useMutation(UPDATE_SUBMISSION_API)

  const [nextStep, nextStepSubStep] = useNextStepSubStep(step, subStep)

  const [
    createFirstApi,
    { loading: loadingCreateMutation, error: errorCreateMutation },
  ] = useMutation(CREATE_FIRST_API, {
    onCompleted({ insert_first_apis }) {
      const firstApiVariables = {
        variables: {
          id: id,
          first_api_id: insert_first_apis.returning[0].id,
          funnel_step: nextStep,
          funnel_substep: nextStepSubStep,
        },
      }
      updateSubmissionFirstApiId(firstApiVariables)
    },
  })

  const [updateStep, { loading: loadingUpdateStep, error: errorUpdateStep }] = useMutation(
    UPDATE_FUNNEL_STEP,
  )
  // Guard clause:
  if (firstApiId !== null) {
    removeFirstApi({
      variables: {
        first_api_id: firstApiId,
        id: id,
      },
    })
  }
  if (loadingRemoveFirstApi) return <Spinner />
  if (errorRemoveFirstApi)
    return <ErrorPage error={errorRemoveFirstApi} description="Can’t remove api" />

  const handleClick = (api_type: string) => {
    const paramsByType = API_TYPES.find((apiParams) => api_type === apiParams.value)
    const apiParams = {
      ...paramsByType?.params,
      api_type: api_type,
      id: id,
      funnel_step: nextStep,
      funnel_substep: nextStepSubStep,
      first_api_id: firstApiId,
    }
    if (api_type !== 'no_back_end') {
      // Create api id with api_type & audience
      const firstApiVariables = {
        variables: {
          api_type: api_type,
          audience: apiParams.audience,
        },
      }
      createFirstApi(firstApiVariables)
    } else {
      // Skip Api type:
      updateStep({ variables: { id: id, funnel_step: nextStep, funnel_substep: nextStepSubStep } })
    }
  }

  if (loadingCreateMutation || loadingUpdateStep || loadingUpdateMutation) return <Spinner />
  if (errorCreateMutation)
    return <ErrorPage error={errorCreateMutation} description="Can’t create api" />
  if (errorUpdateStep) return <ErrorPage error={errorUpdateStep} description="Can’t update step" />
  if (errorUpdateMutation)
    return <ErrorPage error={errorUpdateMutation} description="Can’t submit api" />

  return (
    <>
      <Headline title="What’s your back-end framework?" />
      <CardForm handleClick={handleClick} types={API_TYPES} />
      <SubStepNavigation />
    </>
  )
}

export default ApiForm
