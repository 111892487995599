import PropTypes from 'prop-types'
import React, { FunctionComponent } from 'react'
import { StyledMain } from './style'

const Main: FunctionComponent = (props) => {
  return <StyledMain>{props.children}</StyledMain>
}
Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
