import { useApolloClient, useMutation } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { UPDATE_FUNNEL_SUBSTEP } from '../../graphql/mutations/submission'
import { GET_SUBMISSION_STEP } from '../../graphql/queries/submission'
import { EN, Step } from '../../locales/en'
import {
  StyledCompletedStep,
  StyledCurrentStep,
  StyledCurrentStepInsideCircle,
  StyledCurrentStepOutsideCircle,
  StyledSubStepsNav,
  StyledUpcomingStep,
} from './style'

const getStepState = (currentSubStepIndex: number, index: number) => {
  if (currentSubStepIndex == index) {
    return <CurrentStep />
  } else if (currentSubStepIndex < index) {
    return <UpcomingStep />
  } else {
    return <CompletedStep />
  }
}

const CompletedStep = () => {
  return (
    <StyledCompletedStep>
      <a href="#" />
    </StyledCompletedStep>
  )
}

const CurrentStep = () => {
  return (
    <StyledCurrentStep>
      <a href="#">
        <StyledCurrentStepOutsideCircle>
          <span />
        </StyledCurrentStepOutsideCircle>
        <StyledCurrentStepInsideCircle />
      </a>
    </StyledCurrentStep>
  )
}

const UpcomingStep = () => {
  return (
    <StyledUpcomingStep>
      <a href="#" />
    </StyledUpcomingStep>
  )
}

const SubStepNavigation = (): JSX.Element => {
  const { id }: { id: string } = useParams()

  const [updateSubStep, { loading, error }] = useMutation(UPDATE_FUNNEL_SUBSTEP)

  const client = useApolloClient()
  const { submissions_by_pk } = client.readQuery({
    query: GET_SUBMISSION_STEP,
    variables: {
      id: id,
    },
  })

  if (submissions_by_pk === undefined) return <></>

  if (loading) return <></>
  if (error) return <></>

  const subSteps = EN.steps[submissions_by_pk.funnel_step].subSteps

  const handleClick = (submissionId: string, requestedIndex: number) => {
    if (submissions_by_pk.funnel_substep <= requestedIndex) return

    updateSubStep({ variables: { id: submissionId, funnel_substep: requestedIndex } })
  }
  return (
    <StyledSubStepsNav>
      <ol>
        {subSteps.map((step: Step, index: number) => (
          <div
            onClick={() => handleClick(id, index)}
            data-title={step.title}
            key={`navsubstep-btn-${index}`}
          >
            {getStepState(submissions_by_pk.funnel_substep, index)}
          </div>
        ))}
      </ol>
    </StyledSubStepsNav>
  )
}
export default SubStepNavigation
