import React, { useEffect, useState } from 'react'
import { CopyBlock, nord } from 'react-code-blocks'
import { Application, Api, Tenant } from '../../../utils/interfaces'
import Spinner from '../../elements/Spinner'
import { CodeWrapper, CryptrConfigBlockStyle, Tabs } from './style'

type CryptrConfigBlockProps = {
  clientId: string
  application: Application
  api: Api
  tenant: Tenant
}

const CryptrConfigBlock = (props: CryptrConfigBlockProps): JSX.Element => {
  const { clientId, application, api, tenant } = props
  const { application_type } = application
  const envConfigString = `#.env
  CRYPTR_AUDIENCE=${application.appAudience}
  CRYPTR_TENANT_DOMAIN=${tenant.domain}
  CRYPTR_CLIENT_ID=${clientId}
  CRYPTR_REGION=${tenant.region}
  CRYPTR_DEFAULT_REDIRECT_URI=${application.redirect_uri}`
  const [language, setLanguage] = useState('text')
  const [codeText, setCodeText] = useState(envConfigString)
  const [pane, setPane] = useState(0)

  const theme = nord
  const codeString = `
          const cryptrConfig = {
          audience: '${application.appAudience}',
          tenant_domain: '${tenant.domain}',
          client_id: '${clientId}',
          region: '${tenant.region}',
          default_redirect_uri: '${application.redirect_uri}',
          }`
  const angularCodeString = `// src/environments/environment.ts
  export const environment = {
    cryptrConfig: {
      audience: '${application.appAudience}',
      tenant_domain: '${tenant.domain}',
      client_id: '${clientId}',
      region: '${tenant.region}',
      default_redirect_uri: '${application.redirect_uri}',
      httpInterceptor: {
        apiRequestsToSecure: [
          ${api ? `'${api.apiAudience}'` : '// any required API resource URL'}
        ]
      }
    }
  }`

  useEffect(() => {
    setLanguage(pane == 0 ? 'text' : 'typescript')
    if (pane == 0) {
      setCodeText(envConfigString)
    } else {
      setCodeText(application_type == 'angular' ? angularCodeString : codeString)
    }
  }, [application_type, pane, envConfigString, angularCodeString, codeString])

  if (clientId === '') return <Spinner />

  return (
    <CryptrConfigBlockStyle>
      <Tabs>
        <nav aria-label="Tabs">
          <a
            className={pane === 0 ? 'selected' : 'not-selected'}
            onClick={() => setPane(0)}
            href="#"
          >
            Environment variables
          </a>
          <a
            className={pane === 1 ? 'selected' : 'not-selected'}
            onClick={() => setPane(1)}
            href="#"
          >
            Inline configuration
          </a>
        </nav>
      </Tabs>
      <CodeWrapper>
        <CopyBlock
          key={`${pane}.${application_type}`}
          showLineNumbers={false}
          text={codeText}
          theme={theme}
          language={language}
          codeBlock
        />
      </CodeWrapper>
    </CryptrConfigBlockStyle>
  )
}

export default CryptrConfigBlock
