import styled from '@emotion/styled'
import tw from 'twin.macro'
import { gapY } from '../../style'

export const FormGridTwoCols = styled.div`
  ${tw`
    grid
    grid-cols-2
    divide-x 
    divide-gray-200
  `}
  row-gap: ${gapY};
  .form-column.single-page-application {
    ${tw`
      pr-4
    `}
  }
  .form-column.backend-api {
    ${tw`
      pl-4
    `}
  }
  h3 {
    ${tw`
      h-8
      font-medium
      text-indigo-900
      text-2xl
    `}
  }
`

export const FormGridContent = styled.div`
  ${tw`
    grid
    gap-6
  `}
  row-gap: ${gapY};
`

export const InputCol = styled.div`
  ${tw`
    mt-12
  `}
`
