import { gql } from '@apollo/client/core'

export const GET_API = gql`
  query getApi($first_api_id: uuid!) {
    first_apis_by_pk(id: $first_api_id) {
      id
      api_type
      apiAudience: audience
      created_at
      updated_at
    }
  }
`
