import styled from '@emotion/styled'
import tw from 'twin.macro'
import LgButton from '../LgButton'

const LgButtonWhite = styled(LgButton)`
  ${tw`
    text-indigo-900
    bg-white
    hover:bg-white
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-indigo-500
  `}
`

export default LgButtonWhite
