import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledCompletedStep = styled.li`
  a {
    ${tw`
      block
      w-2.5
      h-2.5
      bg-indigo-600
      rounded-full
      hover:bg-indigo-900
    `}
  }
  span {
    ${tw`
      sr-only
    `}
  }
`

export const StyledCurrentStep = styled.li`
  a {
    ${tw`
      relative
      flex
      items-center
      justify-center
    `}
  }
`
export const StyledCurrentStepInsideCircle = styled.span`
  ${tw`
    relative
    block
    w-2.5
    h-2.5
    bg-indigo-600
    rounded-full
  `}
`

export const StyledCurrentStepOutsideCircle = styled.span`
  ${tw`
    absolute
    flex
    items-center
    justify-center
  `}
  span {
    ${tw`
      w-5
      h-5
      rounded-full
      bg-indigo-200
    `}
  }
`

export const StyledSubStepsNav = styled.nav`
  ${tw`
    my-6
    flex
    items-center
    justify-center
    list-none
  `}
  ol {
    ${tw`
      flex
      items-center
      space-x-5
    `}
  }
`

export const StyledUpcomingStep = styled.li`
  a {
    ${tw`
      block
      w-2.5
      h-2.5
      bg-gray-200
      rounded-full
      hover:bg-gray-400
    `}
  }
`
