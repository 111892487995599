import styled from '@emotion/styled'
import tw from 'twin.macro'

const LgButton = styled.button`
  ${tw`
    flex
    justify-center
    items-center
    px-4
    py-2
    w-full
    border
    border-transparent
    uppercase
    text-sm
    font-medium
    rounded-full
    shadow-sm
    hover:shadow-md
    text-white
    hover:bg-purple-700
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-indigo-500
  `}
  background-color: #5330AC;
  svg {
    ${tw`
      h-3
      w-3
      ml-3
    `}
  }
  :disabled {
    ${tw`
      text-gray-400
      bg-gray-300
    `}
  }
`

export default LgButton
