import React from 'react'
import { DisabledSmallCard, IconCard, SmallCard } from './style'
import SVG from 'react-inlinesvg'

const SmCardContent = ({ logo }: { logo: string }): JSX.Element => {
  return (
    <IconCard>
      <SVG src={logo} />
    </IconCard>
  )
}
const SmCard = ({ logo, disabled }: { logo: string; disabled?: boolean }): JSX.Element => {
  return (
    <>
      {disabled ? (
        <DisabledSmallCard>
          <SmCardContent logo={logo} />
        </DisabledSmallCard>
      ) : (
        <SmallCard>
          <SmCardContent logo={logo} />
        </SmallCard>
      )}
    </>
  )
}

export default SmCard
