import { gql } from '@apollo/client/core'

export const SUBSCRIBE_SUBMISSION_STEP = gql`
  subscription SusbscribeStepSubscription($id: uuid!) {
    submissions_by_pk(id: $id) {
      id
      contact_billing_id
      funnel_step
      funnel_substep
      processing_state
    }
  }
`
