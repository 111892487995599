import { EN } from '../locales/en'

export const NB_STEPS = EN.steps.length

export const useNextStepSubStep = (
  currentStep: number,
  currentSubStep: number,
): [number, number] => {
  let nextSubStep: number =
    currentSubStep < EN.steps[currentStep].subSteps.length - 1 ? currentSubStep + 1 : currentSubStep

  const nextStep: number =
    currentStep < NB_STEPS - 1 && nextSubStep === currentSubStep ? currentStep + 1 : currentStep

  nextSubStep = currentStep !== nextStep ? 0 : nextSubStep

  return [nextStep, nextSubStep]
}
