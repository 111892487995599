export interface Step {
  title: string
  subtitle: string
  subSteps: Step[]
  componentName?: string
}

export const EN: {
  processing: Record<string, string>
  steps: Step[]
  done: Step
} = {
  processing: {
    not_started: 'Your account settings has not started yet',
    requested_processing: 'Your account settings has been resqueted',
    tenant_created: 'Your tenancy created is in progress',
    first_application_created: 'Your first application declaration is in progress',
    private_jwk_created: 'Your private Json Web Key is created',
    public_jwks_created: 'Your public Json Web Key is created',
    account_ready: 'Your account is ready',
  },
  steps: [
    // 0
    {
      title: 'Your favorite technos',
      subtitle: 'To provide you the best advices',
      subSteps: [
        {
          componentName: 'ApplicationForm',
          title: 'Build apps fast, with a ready to use auths',
          subtitle:
            ' An authentication & authorization, customizable, and performant auth API has never been easier.',
          subSteps: [],
        },
        {
          componentName: 'ApiForm',
          title: 'Stop guessing why your users can’t sign',
          subtitle:
            'Easy and quick to login with no password to remember, without compromising on security',
          subSteps: [],
        },
      ],
    },
    // 1
    {
      title: 'Setup your first app',
      subtitle: 'Confirm your ready to use Config',
      subSteps: [
        {
          componentName: 'SettingsForm',
          title: 'Your account is almost ready',
          subtitle: 'We support you in your first steps to define the best possible configuration',
          subSteps: [],
        },
      ],
    },
    // 2
    {
      title: 'Region',
      subtitle: 'Domain URL and Hosting Area',
      subSteps: [
        {
          componentName: 'TenantRegionForm',
          title: 'Your Hosting Area',
          subtitle:
            'These standards are hard to understand, let us the dirty work, & stray focus on your App core',
          subSteps: [],
        },
        {
          componentName: 'TenantDomainForm',
          title: 'Your Entity domain',
          subtitle: 'We support you in your first steps to define the best possible configuration',
          subSteps: [],
        },
      ],
    },
  ],
  // 3
  done: {
    title: 'Your account is ready',
    subtitle: '',
    subSteps: [
      {
        title: '',
        subtitle: '',
        subSteps: [],
      },
    ],
  },
}
