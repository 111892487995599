import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import 'yup-phone'
import { InputFullCol } from '../../ProfileForm/style'
import LgButton from '../../elements/LgButton'
import Headline from '../../Headline'
import { FormTitle } from './style'
import { UPDATE_APPLICATION_SETTINGS } from '../../../graphql/mutations/application_and_api'
import { useMutation } from '@apollo/client'
import { useNextStepSubStep } from '../../../utils/step'
import Spinner from '../../elements/Spinner'
import ErrorPage from '../../ErrorPage'
import { ButtonCol, FormGrid, InputError } from '../../style'
import { AVAILABLE_LOCALES } from '../../../constants'

const localeValues = AVAILABLE_LOCALES.map((locale) => locale.value)

const validationSchema = Yup.object().shape({
  spa_allowed_audience: Yup.string().required('Required, should be a valid URI'),
  spa_redirect_uri: Yup.string().required('Required, should be a valid URI'),
  spa_locale: Yup.string()
    .required(`Please choose one of ${localeValues}`)
    .oneOf(localeValues, `Choose one of ${localeValues}`),
})

type NoBackEndFormProps = {
  audience: string
  redirect_uri: string
  locale: string
  firstApplicationId: string
  id: string
  step: number
  subStep: number
}

const NoBackEndForm = ({
  audience,
  redirect_uri,
  locale,
  step,
  subStep,
  id,
  firstApplicationId,
}: NoBackEndFormProps): JSX.Element => {
  const [updateApplication, { loading, error }] = useMutation(UPDATE_APPLICATION_SETTINGS)
  const [nextStep, nextStepSubStep] = useNextStepSubStep(step, subStep)

  const defaultValues = {
    spa_allowed_audience: audience || '',
    spa_redirect_uri: redirect_uri || '',
    spa_locale: locale || '',
  }

  const formik = useFormik({
    initialValues: defaultValues,

    onSubmit: (values) => {
      const applicationParams = {
        first_application_id: firstApplicationId,
        appAudience: values.spa_allowed_audience,
        redirect_uri: values.spa_redirect_uri,
        locale: values.spa_locale,
        id: id,
        funnel_step: nextStep,
        funnel_substep: nextStepSubStep,
      }
      updateApplication({
        variables: applicationParams,
      })
    },
    validationSchema,
  })

  if (loading) return <Spinner />

  if (error) return <ErrorPage error={error} description="Can’t update application settings" />

  return (
    <div>
      <Headline title="My application" />
      <FormTitle>Single page application</FormTitle>
      <form onSubmit={formik.handleSubmit}>
        <FormGrid>
          <InputFullCol>
            <label htmlFor="spa_allowed_audience">Allowed audience</label>
            <input
              id="spa_allowed_audience"
              name="spa_allowed_audience"
              type="spa_allowed_audience"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.spa_allowed_audience}
            />
            <InputError>
              {formik.touched.spa_allowed_audience && formik.errors.spa_allowed_audience
                ? formik.errors.spa_allowed_audience
                : null}
            </InputError>
          </InputFullCol>
          <InputFullCol>
            <label htmlFor="spa_redirect_uri">Redirect URI</label>
            {/* TODO : use react-select lib */}
            <input
              id="spa_redirect_uri"
              name="spa_redirect_uri"
              type="spa_redirect_uri"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.spa_redirect_uri}
            />
            <InputError>
              {formik.touched.spa_redirect_uri && formik.errors.spa_redirect_uri
                ? formik.errors.spa_redirect_uri
                : null}
            </InputError>
          </InputFullCol>
          <InputFullCol>
            <label htmlFor="spa_locale">Locale</label>
            {/* TODO : use react-select lib */}
            <select
              id="spa_locale"
              name="spa_locale"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.spa_locale}
            >
              <option value="" disabled>
                Application default locale
              </option>
              {AVAILABLE_LOCALES.map((locale) => (
                <option key={locale.value} value={locale.value} label={locale.label} />
              ))}
            </select>
            <InputError>
              {formik.touched.spa_locale && formik.errors.spa_locale
                ? formik.errors.spa_locale
                : null}
            </InputError>
          </InputFullCol>
          <ButtonCol>
            <LgButton type="submit">Let’s go</LgButton>
          </ButtonCol>
        </FormGrid>
      </form>
    </div>
  )
}

export default NoBackEndForm
