import React, { ReactNode } from 'react'
import Main from '../Main'
import Summary from '../Summary'
import './style.css'
import PropTypes from 'prop-types'

type Layout = {
  children: ReactNode
  currentStepIndex: number
  currentSubStepIndex: number
}

const Layout = ({ children, currentStepIndex, currentSubStepIndex }: Layout): JSX.Element => {
  return (
    <div id="layout">
      <Summary currentStepIndex={currentStepIndex} currentSubStepIndex={currentSubStepIndex} />
      <Main>{children}</Main>
    </div>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
