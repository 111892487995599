import React from 'react'
import { HeadlineText, StyledHeadline } from './style'

const Headline = ({ title }: { title: string }): JSX.Element => {
  return (
    <StyledHeadline>
      <HeadlineText>{title}</HeadlineText>
    </StyledHeadline>
  )
}

export default Headline
