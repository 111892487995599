import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import 'yup-phone'
import { FormGridContent, FormGridTwoCols, InputCol } from './style'
import LgButton from '../../elements/LgButton'
import Headline from '../../Headline'
import { useMutation } from '@apollo/client'
import { UPDATE_APPLICATION_AND_API_SETTINGS } from '../../../graphql/mutations/application_and_api'
import { useNextStepSubStep } from '../../../utils/step'
import Spinner from '../../elements/Spinner'
import ErrorPage from '../../ErrorPage'
import { ButtonCol, InputBox, InputError, InputLabel } from '../../style'
import { AVAILABLE_LOCALES } from '../../../constants'

const localeValues = AVAILABLE_LOCALES.map((locale) => locale.value)

const validationSchema = Yup.object().shape({
  spa_allowed_audience: Yup.string().required('Required, should be a valid URI'),
  spa_redirect_uri: Yup.string().required('Required, should be a valid URI'),
  backend_api_allowed_audience: Yup.string().required('Required, should be a valid URI'),
  spa_locale: Yup.string()
    .required(`Please choose one of ${localeValues}`)
    .oneOf(localeValues, `Choose one of ${localeValues}`),
})

type AppAndBackEndFormProps = {
  audience: string
  redirect_uri: string
  locale: string
  apiAudience: string
  firstApplicationId: string
  firstApiId: string
  id: string
  step: number
  subStep: number
}

const AppAndBackEndForm = ({
  id,
  firstApiId,
  firstApplicationId,
  step,
  subStep,
  audience,
  redirect_uri,
  locale,
  apiAudience,
}: AppAndBackEndFormProps): JSX.Element => {
  const [updateApplicationAndApi, { loading, error }] = useMutation(
    UPDATE_APPLICATION_AND_API_SETTINGS,
  )
  const [nextStep, nextStepSubStep] = useNextStepSubStep(step, subStep)
  const defaultValues = {
    spa_allowed_audience: audience || '',
    spa_redirect_uri: redirect_uri || '',
    spa_locale: locale || '',
    backend_api_allowed_audience: apiAudience || '',
  }

  const formik = useFormik({
    initialValues: defaultValues,

    onSubmit: (values) => {
      const applicationAndApiParams = {
        first_application_id: firstApplicationId,
        first_api_id: firstApiId,
        appAudience: values.spa_allowed_audience,
        apiAudience: values.backend_api_allowed_audience,
        redirect_uri: values.spa_redirect_uri,
        locale: values.spa_locale,
        id: id,
        funnel_step: nextStep,
        funnel_substep: nextStepSubStep,
      }
      updateApplicationAndApi({
        variables: applicationAndApiParams,
      })
    },
    validationSchema,
  })

  if (loading) return <Spinner />

  if (error)
    return <ErrorPage error={error} description="Can’t update application and api settings" />

  return (
    <div>
      <Headline title="My application" />
      <form onSubmit={formik.handleSubmit}>
        <FormGridTwoCols>
          <div className="form-column single-page-application">
            <h3>Single page application</h3>
            <InputCol>
              <FormGridContent>
                <div>
                  <InputLabel htmlFor="spa_allowed_audience">Allowed audience</InputLabel>
                  <InputBox>
                    <input
                      id="spa_allowed_audience"
                      name="spa_allowed_audience"
                      type="spa_allowed_audience"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spa_allowed_audience}
                    />
                    <InputError>
                      {formik.touched.spa_allowed_audience && formik.errors.spa_allowed_audience
                        ? formik.errors.spa_allowed_audience
                        : null}
                    </InputError>
                  </InputBox>
                </div>
                <div>
                  <InputLabel htmlFor="spa_redirect_uri">Redirect URI</InputLabel>
                  <InputBox>
                    <input
                      id="spa_redirect_uri"
                      name="spa_redirect_uri"
                      type="spa_redirect_uri"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spa_redirect_uri}
                    />
                    <InputError>
                      {formik.touched.spa_redirect_uri && formik.errors.spa_redirect_uri
                        ? formik.errors.spa_redirect_uri
                        : null}
                    </InputError>
                  </InputBox>
                </div>
                <div>
                  <InputLabel htmlFor="spa_locale">Locale</InputLabel>
                  <InputBox>
                    {/* TODO : use react-select lib */}
                    <select
                      id="spa_locale"
                      name="spa_locale"
                      onChange={formik.handleChange}
                      value={formik.values.spa_locale}
                    >
                      <option value="" disabled>
                        Application default locale
                      </option>
                      {AVAILABLE_LOCALES.map((locale) => (
                        <option key={locale.value} value={locale.value} label={locale.label} />
                      ))}
                    </select>
                    <InputError>
                      {formik.touched.spa_locale && formik.errors.spa_locale
                        ? formik.errors.spa_locale
                        : null}
                    </InputError>
                  </InputBox>
                </div>
              </FormGridContent>
            </InputCol>
          </div>
          <div className="form-column backend-api">
            <h3>Backend Api</h3>
            <InputCol>
              <FormGridContent>
                <div>
                  <InputLabel htmlFor="backend_api_allowed_audience">Allowed audience</InputLabel>
                  <InputBox>
                    <input
                      id="backend_api_allowed_audience"
                      name="backend_api_allowed_audience"
                      type="backend_api_allowed_audience"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.backend_api_allowed_audience}
                    />
                    <InputError>
                      {formik.touched.backend_api_allowed_audience &&
                      formik.errors.backend_api_allowed_audience
                        ? formik.errors.backend_api_allowed_audience
                        : null}
                    </InputError>
                  </InputBox>
                </div>
              </FormGridContent>
            </InputCol>
          </div>
          <ButtonCol>
            <LgButton type="submit">Let’s go</LgButton>
          </ButtonCol>
        </FormGridTwoCols>
      </form>
    </div>
  )
}

export default AppAndBackEndForm
