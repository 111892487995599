import React from 'react'
import { ApolloError } from '@apollo/client'

const ErrorPage = ({
  description,
  error,
}: {
  description: string
  error: ApolloError | undefined
}): JSX.Element => (
  <>
    <h1>Error {description}</h1>
    <p>
      Error : <strong style={{ color: 'red' }}>{JSON.stringify(error)}</strong>
    </p>
  </>
)

export default ErrorPage
