import { gql } from '@apollo/client/core'

export const UPDATE_TENANT_REGION = gql`
  mutation updateTenantRegion(
    $tenantId: uuid!
    $region: String
    $id: uuid!
    $funnel_step: Int!
    $funnel_substep: Int!
  ) {
    update_tenants_by_pk(
      pk_columns: { id: $tenantId }
      _set: { region: $region }
    ) {
      id
      region
    }
    update_submissions_by_pk(
      pk_columns: {id: $id},
      _set: {
        funnel_step: $funnel_step,
        funnel_substep: $funnel_substep
      }
    ) {
      id
      first_application_id
      funnel_step
      funnel_substep
    }
  }
`
