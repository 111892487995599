import React from 'react'
import Header from './Header'
import Steps from './Steps'
import { StyledSummary } from './style'
import { EN } from '../../locales/en'

type SummaryProps = {
  currentStepIndex: number
  currentSubStepIndex: number
}

const Summary = ({ currentStepIndex, currentSubStepIndex }: SummaryProps): JSX.Element => (
  <StyledSummary>
    <Header
      title={EN.steps[currentStepIndex].subSteps[currentSubStepIndex].title}
      description={EN.steps[currentStepIndex].subSteps[currentSubStepIndex].subtitle}
    />
    <Steps steps={EN.steps} currentStepIndex={currentStepIndex} />
  </StyledSummary>
)

export default Summary
