import React, { useState } from 'react'
import Processing from './Processing'
import Result from './Result'

const DonePage = (): JSX.Element => {
  const [processing] = useState(true)

  if (processing) return <Processing />

  return <Result />
}

export default DonePage
