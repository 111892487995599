export const STORAGE_KEY = 'cryptr-developer-onboarding'
export const CRYPTR_DEV_URL = 'http://localhost:4000'
export const CRYPTR_BASE_URL = process.env.REACT_APP_CRYPTR_BASE_URL || 'https://auth.cryptr.eu'

export const FETCH_CLIENT_ID_PATH = '/api/cryptr/onboarding/fetch-client-id'
export const CHECK_DOMAIN_PATH = '/api/cryptr/onboarding/check-domain'

export const ADMIN_TOOL_DEV = 'http://localhost:4000/tenant-admin/login'
export const ADMIN_TOOL_PROD = process.env.REACT_APP_ADMIN_TOOL_PROD || 'https://admin.cryptr.co'

export const CRYPTR_DOCS_BASE_URL = process.env.REACT_APP_CRYPTR_DOCS_BASE_URL || 'https://docs.cryptr.co/docs'

export const AVAILABLE_LOCALES = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
]

export const SELECT_REGION_SERVER = process.env.NODE_ENV === 'development' ? false : process.env.REACT_APP_SELECT_REGION_SERVER !== 'false'
