import {
  ADMIN_TOOL_DEV,
  ADMIN_TOOL_PROD,
  CHECK_DOMAIN_PATH,
  CRYPTR_BASE_URL,
  CRYPTR_DEV_URL,
  FETCH_CLIENT_ID_PATH,
} from '../constants'

const isProd = process.env.NODE_ENV === 'production'
export const cryptrUrl = process.env.CRYPTR_BASE_URL || isProd ? CRYPTR_BASE_URL : CRYPTR_DEV_URL
export const fetchClientIdUrl = (id: string): string =>
  `${cryptrUrl}${FETCH_CLIENT_ID_PATH}?id=${id}`
export const checkDomainUrl = (domain: string): string =>
  `${cryptrUrl}${CHECK_DOMAIN_PATH}?domain=${domain}`
export const adminToolUrl = isProd ? ADMIN_TOOL_PROD : ADMIN_TOOL_DEV
