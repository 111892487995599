import { gql } from '@apollo/client/core'

export const GET_CONTACT_BILLING = gql`
  query GetContactBilling($id: uuid!) {
    contact_billings_by_pk(id: $id) {
      id
      company_name
      company_size
      country
      email
      field_of_work
      first_name
      last_name
      phone_number
      login_url
    }
  }
`
