import React from 'react'
import SVG from 'react-inlinesvg'
import cryptrLogoSvg from '../../../images/cryptr-logo.svg'
import { HeaderText, StyledHeader } from './style'

type HeaderProps = {
  title: string
  description: string
}

const Header = ({ title, description }: HeaderProps): JSX.Element => {
  return (
    <div>
      <StyledHeader>
        <SVG src={cryptrLogoSvg} />
        <HeaderText>
          <h2>{title}</h2>
          <p>{description}</p>
        </HeaderText>
      </StyledHeader>
    </div>
  )
}

export default Header
