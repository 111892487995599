import styled from '@emotion/styled'
import tw from 'twin.macro'

export const InputCol = styled.div`
  ${tw`
    col-span-6
    sm:col-span-3
  `}
`

export const InputFullCol = styled.div`
  ${tw`
    col-span-6
  `}
  label {
    ${tw`
      block
      text-sm
      font-bold
      text-indigo-900
    `}
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%237F7887'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto, 100%;
  }
  input,
  select {
    ${tw`
      mt-1
      shadow-sm
      focus:outline-none
      focus:ring-indigo-500 
      focus:border-indigo-500
      block
      bg-white
      w-full
      text-indigo-900
      sm:text-sm
      border
      border-white
      rounded-md
      px-4
      py-2
    `}
    height: 2.375rem;
    span {
      ${tw`
        absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none
      `}
    }
    svg {
      ${tw`
        h-5 w-5 text-gray-400
      `}
    }
  }
  ::placeholder {
    ${tw`
      text-gray-400
    `}
  }
`
