import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Card = styled.div`
  ${tw`
    rounded-md
    bg-blue-50
    p-4
    shadow-xl
    mt-6
  `}
  div {
    ${tw`
      flex
    `}
  }
`

export const CardIcon = styled.div`
  ${tw`
    rounded-full
    h-8
    w-8
    bg-blue-50
    border-4 
    border-blue-200
    flex
    justify-center
    items-center
  `}
  svg {
    ${tw`
      h-4 
      w-4 
      text-blue-500
    `}
  }
`

export const CardText = styled.div`
  ${tw`
    ml-3
    flex-1
  `}
  p {
    ${tw`
      text-sm
      text-blue-900
    `}
  }
`

export const Description = styled.p`
  ${tw`
    mb-12
    text-indigo-800
    text-sm
  `}
`

export const DomainSlug = styled.div`
  ${tw`
    w-full
    flex
    flex-row
    justify-between
    items-center
    text-2xl
    font-bold
    py-2
    px-4
    rounded-b-md
  `}
  &.success {
    ${tw`
      text-green-400
    `}
  }
  &.checking,
  &.uninitialized {
    ${tw`
      text-green-100
    `}
  }
  &.error {
    ${tw`
      text-red-500
    `}
  }
`
