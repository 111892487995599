import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledProgress = styled.ol`
  ${tw`
    overflow-hidden
    mt-10
  `}
`

export const ProgressItem = styled.li`
  ${tw`
    overflow-hidden
  `}
`

export const ProgressCompleteLine = styled.div`
  ${tw`
    -ml-px
    absolute
    mt-0.5
    top-8
    left-4
    w-0.5
    h-96
    bg-indigo-600
  `}
`

export const ProgressLine = styled.div`
  ${tw`
    -ml-px
    absolute
    mt-0.5
    top-8
    left-4
    w-0.5
    h-96
    bg-gray-300
  `}
`

export const ProgressLink = styled.a`
  ${tw`
    relative
    flex
    items-start
    mb-10
  `}
`

export const ProgressCheckCircle = styled.span`
  ${tw`
    h-9
    flex
    items-center
  `}
  span {
    ${tw`
      relative
      z-10
      w-8
      h-8
      flex
      items-center
      justify-center
      bg-indigo-600
      rounded-full
      hover:bg-indigo-800
    `}
  }
  .complete-number {
    ${tw`
      text-white
      font-bold
      text-sm
    `}
  }
`

export const ProgressCheckText = styled.span`
  ${tw`
    ml-4
    min-w-0
    flex
    flex-col
    text-left
  `}
  .title {
    ${tw`
      text-xs
      font-semibold
      uppercase
      tracking-wide
      text-white
    `}
  }
  .subtitle {
    ${tw`
      text-sm
      text-gray-500
    `}
  }
`

export const ProgressCurrentCircle = styled.span`
  ${tw`
    h-9
    flex
    items-center
  `}
  span {
    ${tw`
      relative
      z-10
      w-8
      h-8
      flex
      items-center
      justify-center
      border-2
      border-indigo-600
      rounded-full
    `}
  }
  .round-check {
    ${tw`
      h-2.5
      w-2.5
      bg-indigo-600
      rounded-full
    `}
  }
  .current-number {
    ${tw`
      text-indigo-600
      font-bold
      text-sm
    `}
  }
`

export const ProgressCurrentText = styled.span`
  ${tw`
    ml-4
    min-w-0
    flex
    flex-col
    text-left
  `}
  .title {
    ${tw`
      text-xs
      font-semibold
      uppercase
      tracking-wide
      text-indigo-600
    `}
  }
  .subtitle {
    ${tw`
      text-sm
      text-gray-500
    `}
  }
`

export const ProgressNextLine = styled.div`
  ${tw`
    -ml-px
    absolute
    mt-0.5
    top-4
    left-4
    w-0.5
    h-full
    bg-gray-300
  `}
`

export const ProgressNextCircle = styled.span`
  ${tw`
    h-9
    flex
    items-center
  `}
  .round {
    ${tw`
      relative
      z-10
      w-8
      h-8
      flex
      items-center
      justify-center
      border-2
      border-gray-300
      rounded-full
      hover:border-gray-400
    `}
  }
  .round-check {
    ${tw`
      h-2.5
      w-2.5
      bg-transparent
      rounded-full
      hover:bg-gray-300
    `}
  }
`

export const ProgressNextText = styled.span`
  ${tw`
    ml-4
    min-w-0
    flex
    flex-col
    text-left
  `}
  .title {
    ${tw`
      text-xs
      font-semibold
      uppercase
      tracking-wide
      text-gray-500
    `}
  }
  .subtitle {
    ${tw`
      text-sm
      text-gray-500
    `}
  }
`
