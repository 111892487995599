import styled from '@emotion/styled'
import tw from 'twin.macro'

export const gapY = '1.25rem'

export const FormGrid = styled.div`
  ${tw`
    grid
    grid-cols-6
    gap-6
  `}
  row-gap: ${gapY};
`

export const InputError = styled.p`
  ${tw`
    mt-1
    text-xs
    text-red-400
    h-4
  `}
`

export const InputHelp = styled(InputError)`
  ${tw`
    text-gray-400
  `}
`

export const ButtonCol = styled.div`
  ${tw`
    col-span-6
  `}
`

export const InputLabel = styled.label`
  ${tw`
    block
    text-sm
    font-bold
    text-indigo-900
  `}
`

export const InputBox = styled.div`
  ${tw`
    mt-1
  `}
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%237F7887'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto, 100%;
  }
  input,
  select {
    ${tw`
      shadow-sm
      focus:outline-none
      focus:ring-indigo-500 
      focus:border-indigo-500
      block
      w-full
      bg-white
      text-indigo-900
      sm:text-sm
      border
      border-white
      rounded-md
      px-4
      py-2
    `}
    height: 2.375rem;
    ::placeholder {
      ${tw`
      text-gray-400
    `}
    }
  }
`
