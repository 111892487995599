import { useLazyQuery, useQuery } from '@apollo/client'
import React from 'react'
// import { GET_APPLICATION_AND_API } from '../../graphql/queries/application_and_api'
import { GET_API } from '../../graphql/queries/first_api'
import { GET_APPLICATION } from '../../graphql/queries/first_application'
import Spinner from '../elements/Spinner'
import ErrorPage from '../ErrorPage'
import SubStepNavigation from '../SubStepsNavigation'
import AppAndBackEndForm from './AppAndBackEndForm'
import NoBackEndForm from './NoBackEndForm'

type SettingsFormProps = {
  firstApiId: string | null
  firstApplicationId: string
  id: string
  step: number
  subStep: number
}

const SettingsForm = ({
  firstApiId,
  firstApplicationId,
  id,
  step,
  subStep,
}: SettingsFormProps): JSX.Element => {
  const { data: appData, loading: loadingAppQuery, error: errorAppQuery } = useQuery(
    GET_APPLICATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        first_application_id: firstApplicationId,
      },
    },
  )
  const [
    loadApi,
    { data: apiData, loading: loadingApiQuery, error: errorApiQuery, called: calledApiQuery },
  ] = useLazyQuery(GET_API, {
    fetchPolicy: 'no-cache',
    variables: {
      first_api_id: firstApiId,
    },
  })

  if (firstApiId && !calledApiQuery) {
    loadApi()
  }

  // 1. Handle API query
  if (calledApiQuery && loadingApiQuery) return <Spinner />

  if (errorApiQuery)
    return <ErrorPage error={errorApiQuery} description="Can’t read api settings" />

  // 2. Handle App query
  if (loadingAppQuery) return <Spinner />

  if (errorAppQuery)
    return <ErrorPage error={errorAppQuery} description="Can’t read application settings" />

  const { appAudience, redirect_uri, locale } = appData.first_applications_by_pk

  return (
    <>
      {firstApiId ? (
        <>
          <AppAndBackEndForm
            audience={appAudience}
            redirect_uri={redirect_uri}
            locale={locale}
            apiAudience={apiData ? apiData.first_apis_by_pk.apiAudience : ''}
            id={id}
            firstApplicationId={firstApplicationId}
            firstApiId={firstApiId}
            step={step}
            subStep={subStep}
          />
        </>
      ) : (
        <NoBackEndForm
          audience={appAudience}
          redirect_uri={redirect_uri}
          locale={locale}
          id={id}
          firstApplicationId={firstApplicationId}
          step={step}
          subStep={subStep}
        />
      )}
      <SubStepNavigation />
    </>
  )
}

export default SettingsForm
