import { gql } from '@apollo/client/core'

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($id: uuid!, $funnel_step: Int!, $funnel_substep: Int!, $application_type: String, $first_application_id: uuid!, $audience: String, $redirect_uri: String, $locale: String) {
    update_first_applications_by_pk(pk_columns: {id: $first_application_id}, _set: {application_type: $application_type, audience: $audience, redirect_uri: $redirect_uri, locale: $locale}) {
      application_type
      audience
      redirect_uri
      locale
    }
    update_submissions_by_pk(pk_columns: {id: $id}, _set: {funnel_step: $funnel_step, funnel_substep: $funnel_substep}) {
      id
      first_application_id
      funnel_step
      funnel_substep
    }
  }
`
