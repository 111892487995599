import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FormTitle = styled.h3`
  ${tw`
    h-8
    mb-12
    font-medium
    text-indigo-900
    text-2xl
  `}
`
