import React from 'react'
import angularLogoSvg from '../../images/angular-logo.svg'
import reactLogoSvg from '../../images/react-logo.svg'
import vueLogoSvg from '../../images/vue-logo.svg'
import Headline from '../Headline'
import SubStepNavigation from '../SubStepsNavigation'
import CardForm from '../CardForm'
import { CardInput } from '../CardForm/CardInput'
import { useMutation, useQuery } from '@apollo/client'
// import { GET_APPLICATION } from '../../graphql/queries/first_application'
import { UPDATE_APPLICATION } from '../../graphql/mutations/first_application'
import { useNextStepSubStep } from '../../utils/step'
import Spinner from '../elements/Spinner'
import ErrorPage from '../ErrorPage'
import { GET_APPLICATION } from '../../graphql/queries/first_application'

type ApplicationFormProps = {
  firstApplicationId: string
  step: number
  subStep: number
  id: string
}

interface appTypesProps extends CardInput {
  id: string
  params: ParamsProps
}

type ParamsProps = {
  audience: string
  redirect_uri: string
  locale: string
}

export const APP_TYPES: appTypesProps[] = [
  {
    id: 'react',
    value: 'react',
    logo: reactLogoSvg,
    label: 'React',
    params: {
      audience: 'http://localhost:3000',
      redirect_uri: 'http://localhost:3000',
      locale: 'en',
    },
  },
  {
    id: 'angular',
    value: 'angular',
    logo: angularLogoSvg,
    label: 'Angular',
    params: {
      audience: 'http://localhost:4200',
      redirect_uri: 'http://localhost:4200',
      locale: 'en',
    },
  },
  {
    id: 'vue',
    value: 'vue',
    logo: vueLogoSvg,
    label: 'Vue',
    params: {
      audience: 'http://localhost:8080',
      redirect_uri: 'http://localhost:8080',
      locale: 'en',
    },
  },
]

const ApplicationForm = ({
  step,
  subStep,
  id,
  firstApplicationId,
}: ApplicationFormProps): JSX.Element => {
  const { error, loading } = useQuery(GET_APPLICATION, {
    variables: { first_application_id: firstApplicationId },
  })
  const [updateApplicationType, { loading: loadingMutation, error: errorMutation }] = useMutation(
    UPDATE_APPLICATION,
  )
  const [nextStep, nextStepSubStep] = useNextStepSubStep(step, subStep)

  if (loading || loadingMutation) return <Spinner />

  if (error) return <ErrorPage error={error} description="Can’t fetch application" />

  if (errorMutation)
    return <ErrorPage error={errorMutation} description="Can’t submit application" />

  const handleClick = (application_type: string) => {
    const paramsByType = APP_TYPES.find(
      (applicationParams) => application_type === applicationParams.id,
    )
    const applicationParams = {
      ...paramsByType?.params,
      id: id,
      funnel_step: nextStep,
      funnel_substep: nextStepSubStep,
      first_application_id: firstApplicationId,
      application_type: application_type,
    }
    updateApplicationType({
      variables: applicationParams,
    })
  }

  return (
    <>
      <Headline title="What’s your front-end framework?" />
      <CardForm handleClick={handleClick} types={APP_TYPES} />
      <SubStepNavigation />
    </>
  )
}

export default ApplicationForm
