import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CryptrConfigBlockStyle = styled.div`
  ${tw`
    ml-8
    w-1/2
  `}
`

export const CodeWrapper = styled.div`
  ${tw`
    h-64
    overflow-auto
  `}
`

export const Tabs = styled.div`
  ${tw`
    border-b
    border-gray-500
    mb-6
  `}
  nav {
    ${tw`
      -mb-px 
      flex
    `}
  }
  a {
    ${tw`
      w-1/2
      text-center
      border-transparent
      text-gray-500
      hover:text-gray-300
      hover:border-gray-300
      whitespace-nowrap
      py-4
      px-1
      border-b-2
      font-medium
      text-sm
    `}
    &.selected {
      ${tw`
        border-indigo-500
        text-gray-300
        whitespace-nowrap
        py-4
        px-1
        border-b-2
        font-medium
        text-sm
      `}
    }
  }
`
