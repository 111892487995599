import React from 'react'
import { CardWithTitle } from './style'
import SmCard from '../../elements/SmCard'

export interface CardInput {
  value: string
  logo: string
  label: string
  description?: string
  disabled?: boolean
  tooltip_text?: string
}

const CardInput = ({
  logo,
  label,
  value,
  description,
  disabled,
  tooltip_text,
}: CardInput): JSX.Element => {
  return (
    <CardWithTitle title={disabled ? tooltip_text : undefined} id={value}>
      <SmCard logo={logo} disabled={disabled} />
      <span className="label">{label}</span>
      <span className="description">{description}</span>
    </CardWithTitle>
  )
}

export default CardInput
