import { gql } from '@apollo/client/core'

export const CREATE_FIRST_API = gql`
  mutation CreateFirstApi($api_type: String, $audience: String) {
    insert_first_apis(objects: {api_type: $api_type, audience: $audience}) {
      returning {
        id
        api_type
        audience
      }
    }
  }
`

export const DELETE_FIRST_API = gql`
  mutation DeleteFirstApi($first_api_id: uuid!) {
    delete_first_apis_by_pk(id: $first_api_id) {
      id
      audience
      api_type
      created_at
      updated_at
    }
  }
`

export const REMOVE_FIRST_API = gql`
  mutation RemoveFirstApi($id: uuid!, $first_api_id: uuid!) {
    update_submissions_by_pk(pk_columns: {id: $id}, _set: {first_api_id: null}) {
      first_api_id
      id
    }
    delete_first_apis_by_pk(id: $first_api_id) {
      id
      audience
      api_type
      created_at
      updated_at
    }
  }
`

export const UPDATE_SUBMISSION_API = gql`
  mutation UpdateSubmissionAPI($id: uuid!, $first_api_id: uuid!, $funnel_step: Int, $funnel_substep: Int) {
    update_submissions_by_pk(pk_columns: {id: $id}, _set: {first_api_id: $first_api_id, funnel_step: $funnel_step, funnel_substep: $funnel_substep}) {
      id
      first_api_id
      funnel_step
      funnel_substep
    }
  }
`