import { useFormik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import slugify from 'slugify'
import LgButton from '../elements/LgButton'
import Headline from '../Headline'
import { InputFullCol } from '../ProfileForm/style'
import { ButtonCol, FormGrid, InputBox, InputError, InputLabel } from '../style'
import * as Yup from 'yup'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_SUBMISSION_STEP } from '../../graphql/queries/submission'
import Spinner from '../elements/Spinner'
import ErrorPage from '../ErrorPage'
import {
  EU_UPDATE_TENANT_NAME_DOMAIN,
  UPDATE_TENANT_NAME_DOMAIN,
  US_UPDATE_TENANT_NAME_DOMAIN,
} from '../../graphql/mutations/tenant_name_and_domain'
import axios from 'axios'
import { checkDomainUrl } from '../../utils/cryptr'
import CHECK_DOMAIN_STATE from '../../enums/checkDomainState.enum'
import Loader from 'react-loader-spinner'
import infoIcon from '../../images/info-i-icon.svg'
import SVG from 'react-inlinesvg'
import { Card, CardIcon, CardText, Description, DomainSlug } from './style'
import check from '../../images/check.svg'
import cross from '../../images/cross.svg'
import pen from '../../images/pen.svg'
import { GET_TENANT_REGION } from '../../graphql/queries/tenant_appli_and_api'
import { SELECT_REGION_SERVER } from '../../constants'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required').min(3, 'Too short'),
  domain: Yup.string().required('Required').min(3, 'Too short'),
  validDomain: Yup.string().oneOf(['true'], 'domain not available'),
})

const slugifyExtension = { _: '-', "'": '-', '&': '-and-' }

const domainFromName = (name: string) => {
  slugify.extend(slugifyExtension)
  return slugify(name, { strict: true, lower: true })
}

type TenantDomainFormProps = {
  id: string
  step: number
  subStep: number
  tenantId: string | null
}

const TenantDomainForm = (props: TenantDomainFormProps): JSX.Element => {
  const { id, tenantId } = props
  const [updateTenantMutation, setUpdateTenantMutation] = useState(UPDATE_TENANT_NAME_DOMAIN)
  const [mutationUpdated, setMutationUpdated] = useState(false)
  const [updateTenantNameDomain, { loading, error }] = useMutation(updateTenantMutation, {
    refetchQueries: [
      {
        query: GET_SUBMISSION_STEP,
        variables: {
          id: id,
        },
      },
    ],
  })
  const [
    loadTenantRegion,
    { data: tenantRegionData, loading: regionLoading, called: regionCalled },
  ] = useLazyQuery(GET_TENANT_REGION, {
    fetchPolicy: 'no-cache',
    variables: {
      tenant_id: tenantId,
    },
  })
  if (!regionCalled) {
    loadTenantRegion()
  } else if (!mutationUpdated && !regionLoading && tenantRegionData !== undefined) {
    const shouldChangeMutation = SELECT_REGION_SERVER && !mutationUpdated
    if (shouldChangeMutation) {
      const {
        tenants_by_pk: { region },
      } = tenantRegionData
      const newRegion = region == 'eu' ? EU_UPDATE_TENANT_NAME_DOMAIN : US_UPDATE_TENANT_NAME_DOMAIN
      setUpdateTenantMutation(newRegion)
      setMutationUpdated(true)
    }
  }
  const [domainSlug, setDomainSlug] = useState('')
  const [checkDomainState, setChechDomainState] = useState(CHECK_DOMAIN_STATE.uninitialized)
  const defaultValues = { name: '', domain: domainSlug, validDomain: 'false' }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (domainSlug.length > 2) {
        setChechDomainState(CHECK_DOMAIN_STATE.checking)
        axios
          .get(checkDomainUrl(domainSlug))
          .then(({ data }: { data: string }) => {
            if (data === 'ok') setChechDomainState(CHECK_DOMAIN_STATE.success)
          })
          .catch((error: unknown) => {
            console.error(error)
            setChechDomainState(CHECK_DOMAIN_STATE.error)
          })
      } else {
        setChechDomainState(CHECK_DOMAIN_STATE.uninitialized)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [domainSlug])
  const formik = useFormik({
    initialValues: defaultValues,

    onSubmit: (values) => {
      const timeStamp = new Date().toISOString()
      const domainParams = {
        ...values,
        id: id,
        domain: domainSlug,
        tenantId: tenantId,
        requested_processing_at: timeStamp,
      }
      updateTenantNameDomain({
        variables: domainParams,
      })
    },
    validationSchema,
  })

  useEffect(() => {
    formik.setFieldValue('validDomain', `${checkDomainState === CHECK_DOMAIN_STATE.success}`)
  }, [checkDomainState])

  if (loading) return <Spinner />
  if (error) return <ErrorPage error={error} description="Can't submit tenant name and domain" />

  const cryptrDomain = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    const slug = domainFromName(e.target.value)
    setDomainSlug(slug)
    formik.setFieldValue('domain', slug)
  }

  let domainSlugClassName
  if (checkDomainState === CHECK_DOMAIN_STATE.success) {
    domainSlugClassName = 'success'
  }
  if (checkDomainState === CHECK_DOMAIN_STATE.error) {
    domainSlugClassName = 'error'
  }
  if (checkDomainState === CHECK_DOMAIN_STATE.checking) {
    domainSlugClassName = 'checking'
  }
  if (checkDomainState === CHECK_DOMAIN_STATE.uninitialized) {
    domainSlugClassName = 'uninitialized'
  }

  return (
    <div>
      <Headline title="Enter your domain" />
      <Description>
        This is where you handle your Cryptr Authentication. In your tenant domain,{' '}
        <strong>you’ll define, manage and store your applications and users.</strong> Also, each
        domain has its <strong>own User Interface branding.</strong>
      </Description>
      <form onSubmit={formik.handleSubmit}>
        <FormGrid>
          <InputFullCol>
            <InputLabel style={{ color: '#FA675E' }}>
              Be careful, you won’t be able to change your domain name afterwards
            </InputLabel>
            <InputBox>
              <input
                style={
                  formik.values.name.length === 0
                    ? {}
                    : { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }
                }
                id="name"
                name="name"
                type="text"
                autoComplete="off"
                onChange={cryptrDomain}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <input
                id="domain"
                name="domain"
                type="hidden"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.domain}
              />
              <input
                id="validDomain"
                name="validDomain"
                type="hidden"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.validDomain}
              />
              {domainSlug !== '' && (
                <DomainSlug
                  className={domainSlugClassName}
                  style={{ backgroundColor: '#292B42', borderRadius: 'unset' }}
                >
                  <p>
                    <span className="text-gray-200 text-lg">https://auth.cryptr.eu/t/</span>
                    <code className="text-xl">{domainSlug}</code>
                  </p>
                  {checkDomainState == CHECK_DOMAIN_STATE.checking && (
                    <Loader type="Puff" color="#D1FBE9" height={20} width={20} />
                  )}
                  {checkDomainState == CHECK_DOMAIN_STATE.success && <SVG src={check} />}
                  {checkDomainState == CHECK_DOMAIN_STATE.uninitialized && <SVG src={pen} />}
                  {checkDomainState == CHECK_DOMAIN_STATE.error && <SVG src={cross} />}
                </DomainSlug>
              )}
              {formik.errors.name && <InputError>{formik.errors.name}</InputError>}
              {formik.touched.name &&
                formik.values.name.length > 2 &&
                formik.errors.validDomain && <InputError>{formik.errors.validDomain}</InputError>}
            </InputBox>
            <Card>
              <div>
                <CardIcon>
                  <SVG src={infoIcon} />
                </CardIcon>
                <CardText>
                  <p>
                    On your Cryptr Backoffice, you can create additional tenants. So you can
                    organize your tenants in a way that isolate completly your users by domain. The
                    “<strong>Multi-tenancy</strong>” is a good practice.
                  </p>
                </CardText>
              </div>
            </Card>
          </InputFullCol>
          <ButtonCol>
            <LgButton disabled={formik.values.validDomain === 'false'} type="submit">
              Let’s go
            </LgButton>
          </ButtonCol>
        </FormGrid>
      </form>
    </div>
  )
}

export default TenantDomainForm
